
       const __ReactNativeSvgLoader = require('react-native-svg-transformer-fix-expo').default({});
        
  const ClosedDarkActive = __ReactNativeSvgLoader(import('./ClosedDarkActive.svg'));
  

  const ClosedDarkColored = __ReactNativeSvgLoader(import('./ClosedDarkColored.svg'));
  

  const ClosedDarkDisabled = __ReactNativeSvgLoader(import('./ClosedDarkDisabled.svg'));
  

  const ClosedDarkResting = __ReactNativeSvgLoader(import('./ClosedDarkResting.svg'));
  

  const ClosedLigthActive = __ReactNativeSvgLoader(import('./ClosedLigthActive.svg'));
  

  const ClosedLigthColored = __ReactNativeSvgLoader(import('./ClosedLigthColored.svg'));
  

  const ClosedLigthDisabled = __ReactNativeSvgLoader(import('./ClosedLigthDisabled.svg'));
  

  const ClosedLigthResting = __ReactNativeSvgLoader(import('./ClosedLigthResting.svg'));
  

  const OpenDarkActive = __ReactNativeSvgLoader(import('./OpenDarkActive.svg'));
  

  const OpenDarkColored = __ReactNativeSvgLoader(import('./OpenDarkColored.svg'));
  

  const OpenDarkDisabled = __ReactNativeSvgLoader(import('./OpenDarkDisabled.svg'));
  

  const OpenDarkResting = __ReactNativeSvgLoader(import('./OpenDarkResting.svg'));
  

  const OpenLightActive = __ReactNativeSvgLoader(import('./OpenLightActive.svg'));
  

  const OpenLightColored = __ReactNativeSvgLoader(import('./OpenLightColored.svg'));
  

  const OpenLightDisabled = __ReactNativeSvgLoader(import('./OpenLightDisabled.svg'));
  

  const OpenLightResting = __ReactNativeSvgLoader(import('./OpenLightResting.svg'));
  

export const Locks = {
  ClosedDarkActive,
  ClosedDarkColored,
  ClosedDarkDisabled,
  ClosedDarkResting,
  ClosedLigthActive,
  ClosedLigthColored,
  ClosedLigthDisabled,
  ClosedLigthResting,
  OpenDarkActive,
  OpenDarkColored,
  OpenDarkDisabled,
  OpenDarkResting,
  OpenLightActive,
  OpenLightColored,
  OpenLightDisabled,
  OpenLightResting,
}

        