/* eslint-disable camelcase */
import {
  IBMPlexSans_100Thin,
  IBMPlexSans_100Thin_Italic,
  IBMPlexSans_200ExtraLight,
  IBMPlexSans_200ExtraLight_Italic,
  IBMPlexSans_300Light,
  IBMPlexSans_300Light_Italic,
  IBMPlexSans_400Regular,
  IBMPlexSans_400Regular_Italic,
  IBMPlexSans_500Medium,
  IBMPlexSans_500Medium_Italic,
  IBMPlexSans_600SemiBold,
  IBMPlexSans_600SemiBold_Italic,
  IBMPlexSans_700Bold,
  IBMPlexSans_700Bold_Italic,
} from '@expo-google-fonts/ibm-plex-sans'
import { Fonts } from 'react-native-paper/lib/typescript/types'

export const FONTS = {
  IBMPlexSans_100Thin,
  IBMPlexSans_100Thin_Italic,
  IBMPlexSans_200ExtraLight,
  IBMPlexSans_200ExtraLight_Italic,
  IBMPlexSans_300Light,
  IBMPlexSans_300Light_Italic,
  IBMPlexSans_400Regular,
  IBMPlexSans_400Regular_Italic,
  IBMPlexSans_500Medium,
  IBMPlexSans_500Medium_Italic,
  IBMPlexSans_600SemiBold,
  IBMPlexSans_600SemiBold_Italic,
  IBMPlexSans_700Bold,
  IBMPlexSans_700Bold_Italic,
  IBMPlexSans: IBMPlexSans_400Regular,
}

export const DEFAULT_FONT = {
  regular: {
    fontFamily: 'IBMPlexSans_400Regular',
    fontWeight: '400',
  },
  medium: {
    fontFamily: 'IBMPlexSans_600SemiBold',
    fontWeight: '600',
  },
  light: {
    fontFamily: 'IBMPlexSans_300Light',
    fontWeight: '300',
  },
  thin: {
    fontFamily: 'IBMPlexSans_100Thin',
    fontWeight: '100',
  },
} as Fonts

export const FONT_CONFIG = {
  web: DEFAULT_FONT,
  ios: DEFAULT_FONT,
  android: DEFAULT_FONT,
}
