/* eslint-disable no-console */

import { formatISO } from 'date-fns'
import AppConfig from '../AppConfig'

const LOG_LEVEL: string = AppConfig.logLevel

const LogLevels: Record<string, number> = {
  ERROR: 0,
  WARN: 1,
  INFO: 2,
  DEBUG: 3,
  TRACE: 4,
}

export default class Logger {
  constructor(className: string) {
    this.className = className
  }

  private className

  private maxLevel = LogLevels[LOG_LEVEL]

  private print(fun: (message?: any, ...optionalParams: any[]) => void, msg: string, ...optionalParams: any[]) {
    let prefix = this.className
    if (AppConfig.logTime) {
      const time = formatISO(new Date())
      prefix = `${time} - ${this.className}`
    }
    if (optionalParams?.length > 0) {
      fun(`${prefix} - ${msg}`, optionalParams)
    } else {
      fun(`${prefix} - ${msg}`)
    }
  }

  public error(msg: string, ...optionalParams: any[]): void {
    if (this.maxLevel >= LogLevels.ERROR) {
      this.print(console.error, msg, ...optionalParams)
    }
  }

  public warn(msg: string, ...optionalParams: any[]): void {
    if (this.maxLevel >= LogLevels.WARN) {
      this.print(console.warn, msg, ...optionalParams)
    }
  }

  public info(msg: string, ...optionalParams: any[]): void {
    if (this.maxLevel >= LogLevels.INFO) {
      this.print(console.info, msg, ...optionalParams)
    }
  }

  public debug(msg: string, ...optionalParams: any[]): void {
    if (this.maxLevel >= LogLevels.DEBUG) {
      this.print(console.debug, msg, ...optionalParams)
    }
  }

  public trace(msg: string, ...optionalParams: any[]): void {
    if (this.maxLevel >= LogLevels.TRACE) {
      this.print(console.info, msg, ...optionalParams)
    }
  }
}
