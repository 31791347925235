export enum Action {
  LOGIN = 'me',
  LOGOUT = 'logout',
  SET_CONFIG = 'config',
  CONFIG_READY = 'configReady',
  SET_THEME = 'theme',
  SNACKBAR = 'snackbar',
  SET_LANGUAGE = 'language',
  SET_FULLSCREEN = 'setFullscreen',
  SET_CONTENT_REFRESHED = 'contentRefreshed',
  SET_PROGRESS_UPDATED = 'progressUpdated',
  SET_PROGRAM_ID = 'setProgramId',
  SET_SELECTED_PROGRAM = 'setSelectedProgram',
  SET_NEW_MODULES = 'setNewModules',
  SET_HEADER_DIMENSIONS = 'setNewModules',
  FLASH_ALERT = 'flashalert',
  SET_NOTIFICATION_ENABLED = 'setNotificationsEnabled',
  SET_LAST_VISITED_ITEM = 'setLastVisitedItem',
  SET_APP_LOCK_CONFIG = 'appLockConfig',
  SET_APP_LOCKED = 'setAppLock',
  SET_BOOKMARKS = 'setBookmarks',
  SET_DASHBOARD_REFRESHED = 'dashboardRefreshed',
  SET_WIKI_REFRESH = 'wikiRefreshed',
  SET_ACTIVE_CHALLENGE_ID = 'setActiveChallengeId',
  SET_COMPLETED_CHALLENGES = 'setCompletedChallenges',
  SET_LAST_OPENED_TIME = 'setLastOpenedTime',
  SET_BACK_ROUTE = 'setBackRoute',
  SET_QUICK_WINS_MODAL_OPEN = 'quickWinsModalOpen',
  SET_QUICK_WINS_AVAILABLE = 'setQuickwinsAvailable',
  SET_HEADER = 'setHeader',
  SET_HEADER_PADDING = 'setHeaderPadding',
  SET_FONT_SIZE = 'setFontSize',
  SET_CONTENT_TOP_BAR = 'setContentTopBar',
  SET_RIGHT_DRAWER_OPEN = 'setRightDrawerOpen',
}
