import { FONTS } from '@ds/fonts/Fonts'
import Icon from '@ds/icons/Icon'
import { Color } from '@ds/theme/Color'
import { Primitives } from '@ds/theme/Primitives'
import { CulchaTheme } from '@ds/theme/Theme'
import Store from '@state/Store'
import AppConfig from '@ts/util/AppConfig'
import { getMobileOS } from '@ts/util/getMobileOS'
import { t } from '@ts/util/i18n'
import { useFonts } from 'expo-font'
import * as Linking from 'expo-linking'
import React, { Suspense, useEffect, useState } from 'react'
import { View } from 'react-native'
import { Text, useTheme } from 'react-native-paper'
import { Button } from './user-app/common/buttons/Button'
import ConfirmationDialog from './user-app/common/modals/ConfirmationDialog'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types

const androidStoreLink = 'https://play.google.com/store/apps/details?id=com.culcha.culcha'
const iosStoreLink = 'https://apps.apple.com/de/app/culcha-2-0/id1570089951'
export default function Root(props): JSX.Element {
  // eslint-disable-next-line no-constant-condition
  let App = null

  if (__BACKOFFICE__) {
    App = React.lazy(() => import('@ts/components/backoffice/App'))
  } else {
    const os = getMobileOS()
    const fonts = useFonts(FONTS)
    const targetUrl = window.location.href.replaceAll(AppConfig.appURL, 'culcha://')
    let isAppInstalled = false
    Linking.canOpenURL(targetUrl).then(res => {
      isAppInstalled = res
    })

    if (os !== null) {
      return <AppDownloadComponent platform={os} appInstalled={isAppInstalled} targetURL={targetUrl} />
    }

    App = React.lazy(() => import('@components/user-app/UserRoot'))
  }
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Store>
        <App {...props} />
      </Store>
    </Suspense>
  )
}

type Props = {
  platform: 'ios' | 'android' | null
  appInstalled: boolean
  targetURL: string
}
const AppDownloadComponent = ({ platform, appInstalled, targetURL }: Props) => {
  const theme = useTheme() as CulchaTheme
  const [open, setOpen] = useState(false)
  const [checkInstall, setCheckInstall] = useState(appInstalled)

  useEffect(() => {
    Linking.canOpenURL(targetURL).then(res => {
      setOpen(true)
      setCheckInstall(res)
    })
  }, [appInstalled])

  const onConfirm = () => {
    let targetUrl = targetURL
    if (!checkInstall) {
      targetUrl = platform === 'android' ? androidStoreLink : iosStoreLink
    }
    Linking.openURL(targetUrl)
  }

  return (
    <ConfirmationDialog
      confirmCta={t('model.profile.confirm')}
      visible={open}
      onAbortPressed={() => {
        setOpen(false)
      }}
      parentComponent={
        <View style={{ flex: 1, justifyContent: 'center' }}>
          <Icon icon="CulchaLogo" height={72} />
        </View>
      }
      confirmationButtonStyle={{ backgroundColor: Color.AccentScale.Red[1], borderWidth: 0 }}
      ctaTextStyle={{ color: Color.Grey[1], ...Primitives.Text.Body.component600 }}
      abortTextStyle={{
        color: theme.dark ? Color.DarkMode.dangerLight : Color.AccentScale.Red[1],
        borderWidth: 0,
      }}
      outerStyle={{
        justifyContent: 'flex-end',
        maxWidth: 400,
      }}
      confirmButton={
        <View style={{ width: '100%', marginTop: 8 }}>
          <Button
            testID="Appdownload_Confirm"
            text={checkInstall ? t('appopen.confirm') : t('appdownload.confirm')}
            onPress={() => onConfirm()}
          />
        </View>
      }
    >
      <View style={{ paddingHorizontal: 16, paddingVertical: 24 }}>
        <Text
          style={[
            Primitives.Text.Body.component600,
            {
              color: theme.dark ? Color.DarkMode.body : Color.Grey[9],
            },
          ]}
        >
          {t('appdownload.info1')}
        </Text>
        <Text
          style={[
            Primitives.Text.Body.component600,
            {
              color: theme.dark ? Color.DarkMode.body : Color.Grey[9],
            },
          ]}
        >
          {t('appdownload.info2')}
        </Text>
      </View>
    </ConfirmationDialog>
  )
}
