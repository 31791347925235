type AppLockConfig = {
  enabled: boolean
  timeout?: number
  type?: AuthType
  pin?: number[] | null
}

export enum AuthType {
  FACE_ID,
  TOUCH_ID,
  IRIS_ID,
  PIN,
}

export default AppLockConfig
