import { createContext } from 'react'
import { initialState } from './State'
import StateChange from './StateChange'

const ApplicationContext = createContext<{
  state: typeof initialState
  dispatch: (s: StateChange) => void
}>({
  state: initialState,
  dispatch: () => {
    /* NOOP */
  },
})

export default ApplicationContext
