import { PrimaryAlertProps } from '@ts/components/user-app/common/alerts/PrimaryAlert'
import NavigationState from '@ts/components/user-app/navigation/NavigtationState'
import AppLockConfig from '@ts/models/auth/AppLockConfig'
import Bookmark from '@ts/models/types/Bookmark'
import Program from '@ts/models/types/Program'
import * as Localization from 'expo-localization'
import { Appearance, ColorSchemeName, Dimensions } from 'react-native'
import User from '../models/types/User'

export type FlashAlertSettings = {
  visible: boolean
  text: string
  icon: string | null
}

export type LastVisitedItem = {
  item: string | any
  route: string
}

type State = {
  me: User | null
  config: Record<string, unknown>
  configReady: boolean
  theme: ColorSchemeName
  snackbar: PrimaryAlertProps | undefined
  language: 'de' | 'en'
  fullscreen: boolean
  selectedProgramId: string | null
  selectedProgram: Program | null
  bookmarks: Bookmark[]
  contentRefreshed: Date | number
  newModules: number
  headerDimensions: { width: number; height: number }
  flashAlert: FlashAlertSettings
  notificationsEnabled: boolean
  lastVisitedItem: LastVisitedItem
  appLock: AppLockConfig
  appLocked: boolean
  progressUpdated: Date | number
  dashboardRefreshed: number
  wikiRefreshed: Date
  activeChallengeId: string | null
  completedChallenges: boolean
  lastOpened: number
  backRoute: string
  quickWinModalOpen: boolean
  quickWinAvailable: boolean
  header: NavigationState
  headerPadding?: number
  fontSize: number
  contentTopBar: JSX.Element | null // BO top bar
  rightDrawerOpen: boolean // BO right content drawer
}

export const initialState: State = {
  me: null,
  config: {},
  configReady: false,
  theme: Appearance.getColorScheme(),
  snackbar: undefined,
  language: getCurrentLanguage(),
  fullscreen: false,
  contentRefreshed: new Date().getTime(),
  selectedProgramId: null,
  selectedProgram: null,
  newModules: 0,
  headerDimensions: { width: Dimensions.get('screen').width, height: 104 },
  flashAlert: {
    visible: false,
    text: '',
    icon: null,
  },
  notificationsEnabled: true,
  lastVisitedItem: { route: '', item: '' } as LastVisitedItem,
  appLock: { enabled: false } as AppLockConfig,
  appLocked: true,
  bookmarks: [],
  progressUpdated: new Date().getTime(),
  dashboardRefreshed: 0,
  wikiRefreshed: new Date(),
  activeChallengeId: null,
  completedChallenges: false,
  lastOpened: new Date().getTime() / 1000,
  backRoute: '',
  quickWinModalOpen: false,
  quickWinAvailable: false,
  header: { title: 'Culcha', drawerOpen: true, showSearchBar: true } as NavigationState,
  fontSize: 0,
  contentTopBar: null,
  headerPadding: undefined,
  rightDrawerOpen: false,
}

function getCurrentLanguage(): 'de' | 'en' {
  const locale = Localization.locale.substring(0, 2)
  if (locale === 'de' || locale === 'en') {
    return locale
  }
  return 'de'
}

export default State
