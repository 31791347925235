/**
 * https://www.figma.com/file/onD5gcZd3wnLmpajyBknql/Mobile%2FNG%2FComponentLibrary?node-id=3%3A196
 *
 * DO NOT BLINDLY ADD COLORS HERE AS YOU WISH. Those are defined primitives and should only be changed or extend, when the Design System changes
 */
export const Color = {
  Grey: {
    /** '#FFFFFF' */
    0: '#FFFFFF',
    /** '#FAFDFF' */
    1: '#FAFDFF',
    /** '#F3FBFF' */
    2: '#F3FBFF',
    /** '#E3F0F6' */
    3: '#E3F0F6',
    /** '#CBDBE4' */
    4: '#CBDBE4',
    /** '#AEBEC8' */
    5: '#AEBEC8',
    /** '#76868E' */
    6: '#76868E',
    /** '#42535E' */
    7: '#42535E',
    /** '#303C43' */
    8: '#303C43',
    /** '#0E1A24' */
    9: '#0E1A24',
    /** '#09151F' */
    10: '#09151F',
    /** '#242D32' */
    Main: '#242D32',
  },
  AccentScale: {
    Blue: {
      /** '#0087FF' */
      0: '#0087FF',
      /** '#0078E3' */
      1: '#0078E3',
      /** '#005EB1' */
      2: '#005EB1',
      /** '#2D29E5' */
      3: '#2D29E5',
      /** '#2FA7CB' */
      4: '#2FA7CB',
    },
    Green: {
      /** '#1FDF64' */
      0: '#1FDF64',
      /** '#00C847' */
      1: '#00C847',
      /** '#169C46' */
      2: '#169C46',
    },
    Red: {
      /** '#DA2F5E' */
      0: '#DA2F5E',
      /** '#BE164F' */
      1: '#BE164F',
      /** '#960F3D' */
      2: '#960F3D',
      /** '#ff4465' */
      3: '#ff4465',
      /** '#FF6581' */
      4: '#FF6581',
    },
    Yellow: {
      /** '#FFE977' */
      0: '#FFE977',
      /** '#FFDA07' */
      1: '#FFDA07',
      /** '#BCB02C' */
      2: '#BCB02C',
    },
    Purple: {
      /** '#7B61FF' */
      0: '#7B61FF',
      /** '#7258F5' */
      1: '#7258F5',
      /** '#7031DE' */
      2: '#7031DE',
    },
  },
  Alpha: {
    Grey: {
      1: 'rgba(250, 253, 255, 0.9);',
      8: 'rgba(14, 26, 36, 0.8);',
    },
    Blue: {
      0: 'rgba(0, 135, 255, 0.5)',
    },
  },
  Secondary: {
    /** '#E9E939' */
    Yellow: '#E9E939',
    /** '#1CE3DE' */
    Turquoise: '#1CE3DE',
    /** '#F28CFF' */
    Pink: '#F28CFF',
    /** '#00A962' */
    Green1: '#00A962',
    /** '#B1FB59' */
    Green2: '#B1FB59',
    /** '#B2ADB1' */
    Grey1: '#B2ADB1',
    /** '#595959' */
    Grey2: '#595959',
  },
  DarkMode: {
    /** '#E0E7EB' */
    body: '#E0E7EB',
    /** '#787E85' */
    description: '#787E85',
    /** '#FAFDFF' */
    button: '#FAFDFF',
    /** '#00A3FF' */
    blueText: '#00A3FF',
    /** '#FF4465' */
    dangerDark: '#FF4465',
    /** '#FF6581' */
    dangerLight: '#FF6581',
  },
  Gradient: {
    blue: ['rgba(0, 135, 255, 1)', 'rgba(0, 148, 255, 1)', 'rgba(45, 41, 229, 1)'],
    blueReversed: ['rgba(45, 41, 229, 1)', 'rgba(0, 148, 255, 1)', 'rgba(0, 135, 255, 1)'],
    grey: ['rgba(14, 26, 36, 0)', 'rgba(14, 26, 36, 0.22)', 'rgba(14, 26, 36, 0.4)', 'rgba(14, 26, 36, 0.8)'],
    greyLight: ['rgba(14, 26, 36, 0)', 'rgba(14, 26, 36, 0.36)', 'rgba(14, 26, 36, 0.85)', 'rgba(14, 26, 36, 1)'],
    purpleToPink: ['#7B61FF', '#FF4465', '#F28CFF'],
    purpleToPinkReversed: ['#F28CFF', '#FF4465', '#7B61FF'],
  },
}

export const hex2rgb = (hex: string): { r: number; g: number; b: number } => {
  const [r, g, b] = hex.match(/\w\w/g)!.map(x => parseInt(x, 16))
  return { r, g, b }
}
