import 'expo-asset'
import 'react-native-gesture-handler'
import * as Localization from 'expo-localization'
import Root from '@components/Root'
import i18n from '@ts/util/i18n'
import { registerRootComponent } from 'expo'
import Configuration, { KEYS } from './src/main/javascript/util/Configuration'

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in the Expo client or in a native build,
// the environment is set up appropriately

new Configuration().init().then(async () => {
  await i18n.init()
  let lang = new Configuration().get(KEYS.LANGUAGE)
  if (!lang) {
    lang = Localization.locale.substring(0, 2)
  }
  await i18n.setCurrentLanguage(lang)
})
registerRootComponent(Root)
