export function getMobileOS(): 'ios' | 'android' | null {
  const userAgent = navigator.userAgent || navigator.vendor

  if (/android/i.test(userAgent)) {
    return 'android'
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'ios'
  }

  return null
}
