import { Platform } from 'react-native'
import DeviceInfo from 'react-native-device-info'

const mobileOS = ['android', 'ios']
class Device {
  static isWeb() {
    return Platform.OS === 'web'
  }

  static async isMobile() {
    const opertaingSystemInfo = await DeviceInfo.getBaseOs()
    return mobileOS.includes(opertaingSystemInfo.toLocaleLowerCase())
  }
}

export default Device
