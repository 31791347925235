import React, { useReducer } from 'react'
import Reducer from './Reducer'
import { initialState } from './State'
import ApplicationContext from './Context'

type Props = {
  children: JSX.Element | JSX.Element[] | null
}

const Store = ({ children }: Props): JSX.Element => {
  const [state, dispatch] = useReducer(Reducer, initialState)
  return <ApplicationContext.Provider value={{ state, dispatch }}>{children}</ApplicationContext.Provider>
}

export default Store
