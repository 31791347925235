import Configuration, { KEYS } from '@js/util/Configuration'
import Program from '@models/types/Program'
import User from '@models/types/User'
import { PrimaryAlertProps } from '@ts/components/user-app/common/alerts/PrimaryAlert'
import NavigationState from '@ts/components/user-app/navigation/NavigtationState'
import AppLockConfig, { AuthType } from '@ts/models/auth/AppLockConfig'
import Bookmark from '@ts/models/types/Bookmark'
import Logger from '@ts/util/logger/Logger'
import { ColorSchemeName } from 'react-native'
import { Action } from './Action'
import State, { FlashAlertSettings, LastVisitedItem } from './State'
import StateChange from './StateChange'

const configuration = new Configuration()

const log = new Logger('Reducer')

const Reducer = (state: State, stateChange: StateChange): State => {
  const { type, payload } = stateChange
  log.trace(type, payload)

  const newState = { ...state }
  switch (type) {
    case Action.LOGIN:
      newState.me = payload as User
      break
    case Action.LOGOUT:
      newState.me = null
      break
    case Action.CONFIG_READY:
      newState.configReady = payload as boolean
      break
    case Action.SET_THEME:
      newState.theme = payload as ColorSchemeName
      break
    case Action.SET_CONFIG:
      newState.config = payload as Record<string, unknown>
      break
    case Action.SNACKBAR:
      newState.snackbar = payload as PrimaryAlertProps
      break
    case Action.SET_LANGUAGE:
      if (payload === 'de' || payload === 'en') {
        newState.language = payload
      }
      break
    case Action.SET_FULLSCREEN:
      newState.fullscreen = !!payload
      break
    case Action.SET_CONTENT_REFRESHED:
      newState.contentRefreshed = new Date().getTime()
      break
    case Action.SET_PROGRAM_ID:
      newState.selectedProgramId = payload as string
      break
    case Action.SET_SELECTED_PROGRAM:
      newState.selectedProgram = payload as Program
      break
    case Action.SET_NEW_MODULES:
      newState.newModules = payload as number
      break
    case Action.SET_HEADER_DIMENSIONS:
      newState.headerDimensions = payload as { height: number; width: number }
      break
    case Action.FLASH_ALERT:
      newState.flashAlert = payload as FlashAlertSettings
      break

    case Action.SET_NOTIFICATION_ENABLED:
      newState.notificationsEnabled = payload as boolean
      break
    case Action.SET_LAST_VISITED_ITEM:
      newState.lastVisitedItem = payload as LastVisitedItem
      break
    case Action.SET_APP_LOCK_CONFIG: {
      const appLock = { ...(payload as AppLockConfig) }
      if (!appLock.timeout || appLock.timeout < 0) {
        appLock.pin = undefined
        appLock.type = undefined
        appLock.enabled = false
      } else if (appLock.type !== undefined) {
        appLock.enabled = appLock.type !== AuthType.PIN || (appLock.type === AuthType.PIN && appLock.pin !== undefined)
      }

      if (!appLock.enabled) {
        newState.appLocked = false
      }

      configuration.set(KEYS.APP_LOCK, appLock)
      newState.appLock = appLock
      break
    }
    case Action.SET_APP_LOCKED:
      newState.appLocked = payload as boolean
      break
    case Action.SET_BOOKMARKS:
      newState.bookmarks = payload as Bookmark[]
      break
    case Action.SET_PROGRESS_UPDATED:
      newState.progressUpdated = payload as Date
      break
    case Action.SET_DASHBOARD_REFRESHED:
      newState.dashboardRefreshed = payload as number
      break
    case Action.SET_WIKI_REFRESH:
      newState.wikiRefreshed = payload as Date
      break

    case Action.SET_ACTIVE_CHALLENGE_ID:
      newState.activeChallengeId = payload as string | null
      break
    case Action.SET_COMPLETED_CHALLENGES:
      newState.completedChallenges = payload as boolean
      break

    case Action.SET_LAST_OPENED_TIME:
      newState.lastOpened = payload as number
      break
    case Action.SET_BACK_ROUTE:
      newState.backRoute = payload as string
      break

    case Action.SET_QUICK_WINS_MODAL_OPEN:
      newState.quickWinModalOpen = payload as boolean
      break
    case Action.SET_QUICK_WINS_AVAILABLE:
      newState.quickWinAvailable = payload as boolean
      break
    case Action.SET_HEADER:
      newState.header = payload as NavigationState
      break
    case Action.SET_HEADER_PADDING:
      newState.headerPadding = (payload || 0) as number
      break
    case Action.SET_FONT_SIZE:
      newState.fontSize = payload as number
      break
    case Action.SET_CONTENT_TOP_BAR:
      newState.contentTopBar = payload as JSX.Element | null
      break
    case Action.SET_RIGHT_DRAWER_OPEN:
      newState.rightDrawerOpen = payload as boolean
      break
    default:
      break
  }
  return newState
}

export default Reducer
