import Icon from '@ds/icons/Icon'
import { Color } from '@ds/theme/Color'
import { Primitives } from '@ds/theme/Primitives'
import { CulchaTheme } from '@ds/theme/Theme'
import Device from '@js/util/Device'
import React from 'react'
import { StyleSheet, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native'
import { Text, useTheme } from 'react-native-paper'
import Modal from './Modal'

type ConfirmationDialogProps = {
  infoText?: string
  abortCta?: string
  confirmCta?: string
  visible: boolean
  onAbortPressed: () => void
  onConfirmPressed?: () => void
  icon?: string
  confirmationButtonStyle?: ViewStyle
  ctaTextStyle?: TextStyle
  children?: JSX.Element | JSX.Element[]
  abortTextStyle?: TextStyle
  infoBoxStyle?: ViewStyle
  abortButtonContainer?: ViewStyle
  outerStyle?: ViewStyle
  confirmButton?: JSX.Element
  infoTextStyle?: TextStyle
  modalInnerStyle?: ViewStyle
  parentComponent?: JSX.Element | null
}
ConfirmationDialog.defaultProps = {
  icon: null,
  confirmationButtonStyle: null,
  ctaTextStyle: null,
  children: null,
  abortTextStyle: null,
  infoBoxStyle: null,
  abortButtonContainer: null,
  outerStyle: null,
  confirmButton: null,
  infoTextStyle: null,
  infoText: null,
  confirmCta: null,
  onConfirmPressed: null,
  modalInnerStyle: null,
  abortCta: null,
  parentComponent: null,
}
export default function ConfirmationDialog({
  infoText,
  abortCta,
  confirmCta,
  icon,
  visible,
  onAbortPressed,
  onConfirmPressed,
  confirmationButtonStyle,
  ctaTextStyle,
  children,
  abortTextStyle,
  infoBoxStyle,
  abortButtonContainer,
  outerStyle,
  confirmButton,
  infoTextStyle,
  modalInnerStyle,
  parentComponent,
}: ConfirmationDialogProps): JSX.Element {
  const styles = buildStyles(useTheme() as CulchaTheme)
  if (!visible) return null
  return (
    <Modal visible={visible} inner={modalInnerStyle} onRequestClose={onAbortPressed}>
      <View style={[styles.outer, outerStyle]}>
        {parentComponent || null}
        <View style={[styles.infoBox, infoBoxStyle]}>
          {children || (
            <Text style={[Primitives.Text.Body.component600, styles.infoText, { ...infoTextStyle }]}>{infoText}</Text>
          )}
          {abortCta ? (
            <View style={[styles.abortButtonContainer, abortButtonContainer]}>
              <TouchableOpacity style={styles.touchable} onPress={onAbortPressed}>
                <Text
                  selectable={false}
                  style={[Primitives.Text.Body.component600, styles.abortText, { ...abortTextStyle }]}
                >
                  {abortCta}
                </Text>
              </TouchableOpacity>
            </View>
          ) : null}
        </View>

        {confirmButton || (
          <View style={[styles.confirmationButton, { ...confirmationButtonStyle }]}>
            <TouchableOpacity style={styles.touchable} onPress={onConfirmPressed}>
              {icon && <Icon height={24} icon={icon} />}
              <Text selectable={false} style={[Primitives.Text.Body.component600, styles.ctaText, { ...ctaTextStyle }]}>
                {confirmCta}
              </Text>
            </TouchableOpacity>
          </View>
        )}
      </View>
    </Modal>
  )
}

function buildStyles(theme: CulchaTheme) {
  return StyleSheet.create({
    outer: {
      width: '100%',
      alignItems: 'center',
      justifyContent: Device.isWeb() ? 'center' : 'flex-end',
      maxWidth: 1000,
      height: '100%',
    },
    confirmationButton: {
      marginTop: 8,
      paddingVertical: 18,
      width: '100%',
      borderWidth: 1.5,
      borderColor: theme.colors.defaultBorderColor,
      borderRadius: 4,
      backgroundColor: theme.colors.surface,
    },
    ctaText: {
      marginLeft: 4,
    },
    infoBox: {
      backgroundColor: theme.dark ? Color.Grey[8] : Color.Grey[1],
      width: '100%',
      borderWidth: 1.5,
      borderColor: theme.dark ? Color.Grey[7] : Color.Grey[4],
      alignItems: 'center',
      borderRadius: 4,
    },
    infoText: {
      textAlign: 'center',
      marginHorizontal: 48,
      marginVertical: 24,
    },
    touchable: {
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
    },
    abortText: {
      color: theme.dark ? Color.DarkMode.dangerLight : Color.AccentScale.Red[1],
      padding: 10,
    },
    abortButtonContainer: {
      width: '100%',
      paddingVertical: 10,
      borderColor: theme.dark ? Color.Grey[7] : Color.Grey[4],
      borderTopWidth: 1,
    },
  })
}
