import AsyncStorage from '@react-native-async-storage/async-storage'
import Logger from '@ts/util/logger/Logger'
import { Action } from '../../typescript/state/Action'
import Dispatcher from '../../typescript/state/Dispatcher'

let instance = null

export const KEYS = {
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
  USERNAME: 'username',
  USER_ROLES: 'userRoles',
  THEME: 'theme',
  USER_ID: 'userId',
  LANGUAGE: 'language',
  SELECTED_PROGRAM_ID: 'selectedProgramId',
  NOTIFCATIONS_ENABLED: 'notifcationsEnabled',
  APP_LOCK: 'appLock',
  APP_LOCKED: 'appLocked',
  FONT_SIZE: 'fontSize',
  TRADUORA_TOKEN: 'traduoraToken',
  TRADUORA_TOKEN_EXPIRES: 'traduoraTokenExpires',
  PASSWORD_LINK: 'passwordLink',
  QUICK_WIN_SNOOZE_TIME: 'quickWinSnooze',
}

export const CONSTANTS = {
  config: 'culcha.app.config',
  keys: KEYS,
}

const log = new Logger('Configuration')
export default class Configuration {
  initiated = false

  configMap = {}

  constructor() {
    if (instance === null) {
      instance = this
    }
    return instance
  }

  async init() {
    if (this.initiated === false) {
      try {
        const value = await AsyncStorage.getItem(CONSTANTS.config)
        if (value) {
          this.configMap = JSON.parse(value)
        }
      } catch (e) {
        log.error(e)
        this.configMap = {}
        await this.persist()
      }
      Dispatcher.dispatch(Action.SET_FONT_SIZE, this.get(KEYS.FONT_SIZE, 0))
      this.initiated = true
      Dispatcher.dispatch(Action.CONFIG_READY, true)

      return true
    }
    return false
  }

  get(key, defaultValue) {
    try {
      return this.configMap[key] || defaultValue
    } catch (e) {
      log.error(e)
      this.configMap = {}
      this.persist()
      return null
    }
  }

  set(key, value) {
    log.debug(`Setting ${key} to ${JSON.stringify(value)}`)
    this.configMap[key] = value
    this.persist().then(() => {
      Dispatcher.dispatch(Action.SET_CONFIG, this.configMap)
    })
  }

  async remove(key) {
    try {
      delete this.configMap[key]
      this.persist()
    } catch (e) {
      log.error(e)
    }
  }

  async persist() {
    try {
      await AsyncStorage.setItem(CONSTANTS.config, JSON.stringify(this.configMap))
    } catch (e) {
      log.error(e)
    }
  }

  async reset() {
    this.configMap = {}
    await this.persist()
  }

  setFontSize(fontSize) {
    this.set(KEYS.FONT_SIZE, fontSize)
    Dispatcher.dispatch(Action.SET_FONT_SIZE, fontSize)
  }

  setDarkMode(enabled) {
    const newTheme = enabled ? 'dark' : 'default'
    this.set(KEYS.THEME, newTheme)
    Dispatcher.dispatch(Action.SET_THEME, newTheme)
  }
}
