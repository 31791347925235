import { useTheme } from '@react-navigation/native'
import { BlurView } from 'expo-blur'
import React from 'react'
import { Modal as RModal, Platform, StyleSheet, ViewStyle } from 'react-native'

export type ModalProps = {
  visible: boolean
  children: JSX.Element | JSX.Element[]
  inner?: ViewStyle
  intensity?: number
  animationType?: 'none' | 'slide' | 'fade' | undefined
  onRequestClose?: (() => void) | undefined
}

/**
 * @deprecated use ModalBase instead
 */
export default function Modal({
  visible,
  children,
  inner,
  intensity,
  animationType,
  onRequestClose,
}: ModalProps): JSX.Element | null {
  const innerStyles: ViewStyle[] = [STYLES.inner]
  if (inner) innerStyles.push(inner)

  const theme = useTheme()
  if (!visible) return null

  return (
    <RModal
      animationType={animationType}
      transparent
      visible={visible}
      style={STYLES.outer}
      onRequestClose={onRequestClose}
    >
      <BlurView tint={theme.dark ? 'dark' : 'light'} intensity={intensity} style={innerStyles}>
        {children}
      </BlurView>
    </RModal>
  )
}

Modal.defaultProps = {
  intensity: Platform.OS === 'android' ? 135 : 70,
  animationType: 'slide',
  onRequestClose: undefined,
  inner: {},
}

const STYLES = StyleSheet.create({
  outer: {
    width: '100%',
    height: '100%',
    zIndex: 97,
    borderWidth: 0,
  },
  inner: {
    width: '100%',
    height: '100%',
    zIndex: 99,
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingHorizontal: 16,
    paddingBottom: 40,
  },
})
