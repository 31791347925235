import { StyleSheet, TextStyle } from 'react-native'

export type FontConfig = {
  fontFamily:
    | 'IBMPlexSans_100Thin'
    | 'IBMPlexSans_100Thin_Italic'
    | 'IBMPlexSans_200ExtraLight'
    | 'IBMPlexSans_200ExtraLight_Italic'
    | 'IBMPlexSans_300Light'
    | 'IBMPlexSans_300Light_Italic'
    | 'IBMPlexSans_400Regular'
    | 'IBMPlexSans_400Regular_Italic'
    | 'IBMPlexSans_500Medium'
    | 'IBMPlexSans_500Medium_Italic'
    | 'IBMPlexSans_600SemiBold'
    | 'IBMPlexSans_600SemiBold_Italic'
    | 'IBMPlexSans_700Bold'
    | 'IBMPlexSans_700Bold_Italic'
    | 'IBMPlexSans'
  fontSize: number
  fontStyle?: 'normal' | 'italic'
  lineHeight?: number
  letterSpacing?: number
}

type FontStyle = {
  [style in string]: FontConfig
}

export const Body: Record<string, TextStyle> = {
  component: {
    fontFamily: 'IBMPlexSans_400Regular',
    fontSize: 14,
    fontStyle: 'normal',
    lineHeight: 20,
    letterSpacing: 0.16,
  },
  component600: {
    fontFamily: 'IBMPlexSans_600SemiBold',
    fontSize: 14,
    fontStyle: 'normal',
    lineHeight: 20,
    letterSpacing: 0.16,
  },
  expressive: {
    fontFamily: 'IBMPlexSans_400Regular',
    fontSize: 16,
    fontStyle: 'normal',
    lineHeight: 20,
  },
  expressive600: {
    fontFamily: 'IBMPlexSans_600SemiBold',
    fontSize: 16,
    fontStyle: 'normal',
    lineHeight: 20,
  },
}

export const Heading: Record<string, TextStyle> = {
  h1: {
    fontFamily: 'IBMPlexSans_600SemiBold',
    fontSize: 14,
    fontStyle: 'normal',
    lineHeight: 20,
    letterSpacing: 0.16,
  },
  h2: {
    fontFamily: 'IBMPlexSans_600SemiBold',
    fontSize: 16,
    fontStyle: 'normal',
    lineHeight: 24,
  },
  h3: {
    fontFamily: 'IBMPlexSans_600SemiBold',
    fontSize: 20,
    fontStyle: 'normal',
    lineHeight: 28,
  },
  h4: {
    fontFamily: 'IBMPlexSans_600SemiBold',
    fontSize: 28,
    fontStyle: 'normal',
    lineHeight: 36,
  },
  h5: {
    fontFamily: 'IBMPlexSans_600SemiBold',
    fontSize: 36,
    fontStyle: 'normal',
    lineHeight: 36,
  },
}

export const misc: Record<string, TextStyle> = {
  description: {
    fontFamily: 'IBMPlexSans_300Light',
    fontSize: 12,
    fontStyle: 'normal',
    lineHeight: 20,
    letterSpacing: 0.32,
  },
  description600: {
    fontFamily: 'IBMPlexSans_600SemiBold',
    fontSize: 12,
    fontStyle: 'normal',
    lineHeight: 16,
    letterSpacing: 0.32,
  },
  suggestion: {
    fontFamily: 'IBMPlexSans_400Regular_Italic',
    fontSize: 14,
    fontStyle: 'italic',
    lineHeight: 20,
    letterSpacing: 0.16,
  },
  empathic: {
    fontFamily: 'IBMPlexSans_400Regular',
    fontSize: 20,
    fontStyle: 'normal',
    lineHeight: 20,
    letterSpacing: 0.16,
  },
  dataAxis: {
    fontFamily: 'IBMPlexSans_400Regular',
    fontSize: 10,
    fontWeight: '300',
    lineHeight: 13,
    letterSpacing: 0.31,
    textAlign: 'left',
  },
}

const StyledBody = StyleSheet.create(Body)
const StyledHeading = StyleSheet.create(Heading)
const StyledMisc = StyleSheet.create(misc)
export const Primitives = {
  Text: { Body: StyledBody, Heading: StyledHeading, misc: StyledMisc },
}

export const RawPrimitives = {
  Text: { Body, Heading, misc },
}
