import React from 'react'
import { StyleSheet } from 'react-native'
// eslint-disable-next-line import/no-named-default
import { default as LGradient } from 'react-native-web-linear-gradient'
import { LinearGradientProps } from './LinearGradientProps'

const styles = StyleSheet.create({
  gradient: {
    flex: 1,
    zIndex: 0,
    height: '100%',
  },
})

const LinearGradient = ({ children, colors, start, end, style, useAngle, angle }: LinearGradientProps): JSX.Element => (
  <LGradient colors={colors} start={start} end={end} useAngle={useAngle} angle={angle} style={[styles.gradient, style]}>
    {children}
  </LGradient>
)

LinearGradient.defaultProps = {
  colors: ['rgba(0, 0, 0, 0)', `rgba(0, 0, 0, 1)`],
  start: { x: 0, y: 0 },
  end: { x: 0, y: 0.5 },
  style: {},
}

export default LinearGradient
