import { HasTestID } from '@components/common/props/HasTestID'
import Icon from '@ds/icons/Icon'
import { Color } from '@ds/theme/Color'
import { Primitives } from '@ds/theme/Primitives'
import { CulchaTheme } from '@ds/theme/Theme'
import Device from '@js/util/Device'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Animated, LayoutChangeEvent, Platform, Pressable, StyleSheet, TextStyle, View, ViewStyle } from 'react-native'
import { Text, useTheme } from 'react-native-paper'
import LinearGradient from '../gradient/LinearGradient'

type ButtonProps = {
  onPress: () => void
  type?: 'outlined' | 'primary' | 'danger'
  ghost?: boolean
  size?: 'xsmall' | 'small' | 'medium'
  iconPosition?: 'left' | 'right'
  disabled?: boolean
  icon?: string | JSX.Element
  iconHeight?: number
  text?: string
  stretch?: boolean
  backgroundColor?: string
  containerStyle?: ViewStyle
  overrideTheme?: null | 'dark' | 'light'
  textStyle?: TextStyle
  onLayout?: (event: LayoutChangeEvent) => void
} & HasTestID

type SizeMapping = {
  xsmall: number
  small: number
  medium: number
}

const BUTTON_HEIGHT: SizeMapping = {
  xsmall: 32,
  small: 40,
  medium: 56,
}

const VERTICAL_PADDING: SizeMapping = {
  xsmall: 8,
  small: 16,
  medium: 16,
}

const GRADIENT_BLUE = ['rgba(0, 135, 255, 1)', 'rgba(0, 148, 255, 1)', 'rgba(45, 41, 229, 1)']

export function Button({
  onPress,
  text,
  icon,
  iconPosition,
  type,
  disabled,
  size,
  stretch,
  backgroundColor,
  containerStyle,
  overrideTheme,
  testID,
  textStyle,
  iconHeight,
  onLayout,
  ghost,
}: ButtonProps): JSX.Element {
  const [pressing, setPressing] = useState(false)
  const theme: CulchaTheme = { ...useTheme() } as CulchaTheme

  if (overrideTheme) {
    theme.dark = overrideTheme === 'dark'
  }

  const buttonSize = size || 'medium'
  const styles = useMemo(() => getStyles(theme, BUTTON_HEIGHT[buttonSize], VERTICAL_PADDING[buttonSize]), [buttonSize])

  const fadeAnim = useRef(new Animated.Value(disabled ? 0.5 : 1)).current

  const fadeIn = () => {
    Animated.timing(fadeAnim, {
      useNativeDriver: !Device.isWeb(),
      toValue: 1,
      duration: 300,
    }).start()
  }

  const fadeOut = () => {
    Animated.timing(fadeAnim, {
      useNativeDriver: !Device.isWeb(),
      toValue: Platform.OS === 'android' ? 1 : 0.5,
      duration: 300,
    }).start()
  }

  useEffect(() => {
    if (disabled) {
      fadeOut()
    } else {
      fadeIn()
    }
  }, [disabled])

  const additionalStyles: Styles[] = []
  switch (type) {
    case 'danger':
      additionalStyles.push(getErrorActiveStyles(theme))
      break
    case 'primary':
      if (pressing) {
        additionalStyles.push(getPrimaryActiveStyles(theme))
      } else {
        additionalStyles.push(getPrimaryRestingStyles(theme))
      }
      break
    case 'outlined': {
      if (pressing) {
        additionalStyles.push(getOutlinedActiveStyles(theme))
      } else {
        additionalStyles.push(getOutlinedRestingStyles(theme))
      }
      break
    }
    default:
      throw new Error(`${type} is not a valid option for type`)
  }

  const textStyles = [styles.text]
  const containerStyles: ViewStyle[] = [styles.container]
  const gradientStyle: ViewStyle[] = [styles.gradient || {}]
  additionalStyles.forEach(style => {
    textStyles.push(style.text)
    containerStyles.push(style.container)
  })

  if (!text) {
    containerStyles.push({
      borderRadius: BUTTON_HEIGHT[buttonSize],
      width: BUTTON_HEIGHT[buttonSize],
    })
    gradientStyle.push({
      borderRadius: BUTTON_HEIGHT[buttonSize],
      width: BUTTON_HEIGHT[buttonSize] + 2,
      height: BUTTON_HEIGHT[buttonSize] + 2,
      justifyContent: 'center',
      alignItems: 'center',
    })
  }
  if (backgroundColor) {
    containerStyles.push({ backgroundColor })
  }

  let iconComponent = null
  if (icon && typeof icon === 'string') {
    iconComponent = (
      <View style={{ opacity: disabled ? 0.5 : 1 }}>
        <Icon icon={icon} height={iconHeight || 24} active={pressing} />
      </View>
    )
  } else if (icon) {
    iconComponent = icon
  }

  if (!iconComponent) {
    textStyles.push({ marginLeft: 0 })
  }

  if (textStyle) {
    textStyles.push(textStyle)
  }
  const font = type === 'primary' && !ghost ? Primitives.Text.Body.component : Primitives.Text.Body.component600
  const textComponent = text ? (
    <Text selectable={false} style={[font, textStyles]}>
      {text}
    </Text>
  ) : null

  const onPressIn = () => {
    if (!disabled) {
      setPressing(true)
    }
  }

  const onPressOut = () => {
    if (!disabled) {
      setTimeout(() => {
        onPress()
      }, 50)
      setTimeout(() => {
        setPressing(false)
      }, 200)
    }
  }

  const gradientColor = useMemo(() => {
    if (type === 'outlined') {
      return GRADIENT_BLUE
    }
    if (disabled && Platform.OS === 'android') {
      return ['rgba(12, 149, 190, 0.5)', 'rgba(38, 126, 255, 0.5)', 'rgba(45, 41, 229, 0.5)']
    }
    return ['transparent', 'transparent', 'transparent']
  }, [type, disabled])

  if (disabled && Platform.OS === 'android') {
    textStyles.push({ opacity: 0.5 })
  }

  if (stretch) {
    gradientStyle.push({ width: '100%', maxWidth: '100%' })
    containerStyles.push({ width: '100%', maxWidth: '100%' })
  }

  const inner = (
    <Pressable testID={testID} onPress={onPressIn} onPressIn={onPressIn} onPressOut={onPressOut} onLayout={onLayout}>
      <Animated.View style={[containerStyles, { cursor: disabled ? 'not-allowed' : 'pointer' }]}>
        {iconPosition === 'left' ? iconComponent : null}
        {textComponent}
        {iconPosition === 'right' ? iconComponent : null}
      </Animated.View>
    </Pressable>
  )

  if (ghost) {
    return <View style={containerStyle}>{inner}</View>
  }

  return (
    <Animated.View
      style={{
        opacity: fadeAnim,
        ...containerStyle,
      }}
      testID="animatedTestOpacity"
    >
      <LinearGradient style={gradientStyle} colors={gradientColor} start={{ x: 0, y: 0 }} end={{ x: 1, y: 1 }}>
        {inner}
      </LinearGradient>
    </Animated.View>
  )
}
Button.defaultProps = {
  ghost: false,
  icon: null,
  text: null,
  disabled: false,
  iconPosition: 'left',
  type: 'outlined',
  size: 'medium',
  stretch: false,
  backgroundColor: null,
  containerStyle: null,
  overrideTheme: null,
  textStyle: null,
  iconHeight: 24,
  onLayout: undefined,
}

type Styles = {
  container: ViewStyle
  text: TextStyle
  gradient?: ViewStyle
  gradientChild?: ViewStyle
}

function getStyles(theme: CulchaTheme, size: number, verticalPadding: number): Styles {
  return StyleSheet.create({
    container: {
      borderRadius: 4,
      paddingLeft: verticalPadding,
      paddingRight: verticalPadding,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      height: size,
    },
    text: {
      marginLeft: 8,
    },
    gradient: {
      padding: 1,
      borderRadius: 4,
      height: 'auto',
      maxWidth: 500,
    },
    gradientChild: {},
  })
}

function getPrimaryRestingStyles(theme: CulchaTheme): Styles {
  return StyleSheet.create({
    container: {
      backgroundColor: theme.dark ? Color.DarkMode.blueText : Color.AccentScale.Blue[0],
    },
    text: {
      ...Primitives.Text.Body.component,
      color: Color.Grey['1'],
    },
  })
}

function getPrimaryActiveStyles(theme: CulchaTheme): Styles {
  return StyleSheet.create({
    container: {
      backgroundColor: theme.dark ? Color.Grey[8] : Color.AccentScale.Blue[2],
    },
    text: {
      ...Primitives.Text.Body.component,
      color: Color.Grey['1'],
    },
  })
}

function getErrorActiveStyles(theme: CulchaTheme): Styles {
  return StyleSheet.create({
    container: {
      backgroundColor: 'transparent',
    },
    text: {
      ...Primitives.Text.Body.component600,
      color: theme.dark ? Color.AccentScale.Red[4] : Color.AccentScale.Red[1],
    },
  })
}

function getOutlinedRestingStyles(theme: CulchaTheme): Styles {
  return StyleSheet.create({
    container: {
      backgroundColor: theme.dark ? Color.Grey['10'] : Color.Grey['1'],
    },
    text: {
      ...Primitives.Text.Body.component600,
      color: theme.dark ? Color.Grey['1'] : Color.Grey['9'],
    },
  })
}

function getOutlinedActiveStyles(theme: CulchaTheme): Styles {
  return StyleSheet.create({
    container: {
      backgroundColor: theme.dark ? Color.Grey['10'] : Color.Grey['4'],
    },
    text: {
      ...Primitives.Text.Body.component600,
      color: theme.dark ? theme.colors.culcha.blueText : Color.Grey['9'],
    },
  })
}
