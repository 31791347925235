
       const __ReactNativeSvgLoader = require('react-native-svg-transformer-fix-expo').default({});
        import React from 'react'
import { useTheme } from 'react-native-paper'

  const ArrowDownActive = __ReactNativeSvgLoader(import('./assets/ArrowDownActive.svg'));
  

  const ArrowDownDisabled = __ReactNativeSvgLoader(import('./assets/ArrowDownDisabled.svg'));
  

  const ArrowDownResting = __ReactNativeSvgLoader(import('./assets/ArrowDownResting.svg'));
  

  const ArrowHeadActive = __ReactNativeSvgLoader(import('./assets/ArrowHeadActive.svg'));
  

  const ArrowHeadResting = __ReactNativeSvgLoader(import('./assets/ArrowHeadResting.svg'));
  

  const ArrowLeftActive = __ReactNativeSvgLoader(import('./assets/ArrowLeftActive.svg'));
  

  const ArrowLeftDark = __ReactNativeSvgLoader(import('./assets/ArrowLeftDark.svg'));
  

  const ArrowLeftDisabled = __ReactNativeSvgLoader(import('./assets/ArrowLeftDisabled.svg'));
  

  const ArrowLeftResting = __ReactNativeSvgLoader(import('./assets/ArrowLeftResting.svg'));
  

  const ArrowUpActive = __ReactNativeSvgLoader(import('./assets/ArrowUpActive.svg'));
  

  const ArrowUpDisabled = __ReactNativeSvgLoader(import('./assets/ArrowUpDisabled.svg'));
  

  const ArrowUpResting = __ReactNativeSvgLoader(import('./assets/ArrowUpResting.svg'));
  

  const AudioActive = __ReactNativeSvgLoader(import('./assets/AudioActive.svg'));
  

  const AudioDark = __ReactNativeSvgLoader(import('./assets/AudioDark.svg'));
  

  const AudioDisabled = __ReactNativeSvgLoader(import('./assets/AudioDisabled.svg'));
  

  const AudioNotUploaded = __ReactNativeSvgLoader(import('./assets/AudioNotUploaded.svg'));
  

  const AudioResting = __ReactNativeSvgLoader(import('./assets/AudioResting.svg'));
  

  const AudioUploaded = __ReactNativeSvgLoader(import('./assets/AudioUploaded.svg'));
  

  const AudioUploading = __ReactNativeSvgLoader(import('./assets/AudioUploading.svg'));
  

  const AwardActive = __ReactNativeSvgLoader(import('./assets/AwardActive.svg'));
  

  const AwardDark = __ReactNativeSvgLoader(import('./assets/AwardDark.svg'));
  

  const AwardDisabled = __ReactNativeSvgLoader(import('./assets/AwardDisabled.svg'));
  

  const AwardResting = __ReactNativeSvgLoader(import('./assets/AwardResting.svg'));
  

  const BWDActive = __ReactNativeSvgLoader(import('./assets/BWDActive.svg'));
  

  const BWDDark = __ReactNativeSvgLoader(import('./assets/BWDDark.svg'));
  

  const BWDDisabled = __ReactNativeSvgLoader(import('./assets/BWDDisabled.svg'));
  

  const BWDResting = __ReactNativeSvgLoader(import('./assets/BWDResting.svg'));
  

  const BackActive = __ReactNativeSvgLoader(import('./assets/BackActive.svg'));
  

  const BackDark = __ReactNativeSvgLoader(import('./assets/BackDark.svg'));
  

  const BackDisabled = __ReactNativeSvgLoader(import('./assets/BackDisabled.svg'));
  

  const BackResting = __ReactNativeSvgLoader(import('./assets/BackResting.svg'));
  

  const BackSpaceActive = __ReactNativeSvgLoader(import('./assets/BackSpaceActive.svg'));
  

  const BackSpaceActiveDark = __ReactNativeSvgLoader(import('./assets/BackSpaceActiveDark.svg'));
  

  const BackSpaceDisabled = __ReactNativeSvgLoader(import('./assets/BackSpaceDisabled.svg'));
  

  const BackSpaceResting = __ReactNativeSvgLoader(import('./assets/BackSpaceResting.svg'));
  

  const BackwardActive = __ReactNativeSvgLoader(import('./assets/BackwardActive.svg'));
  

  const BackwardResting = __ReactNativeSvgLoader(import('./assets/BackwardResting.svg'));
  

  const Bars = __ReactNativeSvgLoader(import('./assets/Bars.svg'));
  

  const BarsBackground = __ReactNativeSvgLoader(import('./assets/BarsBackground.svg'));
  

  const BinocularDark = __ReactNativeSvgLoader(import('./assets/BinocularDark.svg'));
  

  const BinocularResting = __ReactNativeSvgLoader(import('./assets/BinocularResting.svg'));
  

  const BoldActive = __ReactNativeSvgLoader(import('./assets/BoldActive.svg'));
  

  const BoldResting = __ReactNativeSvgLoader(import('./assets/BoldResting.svg'));
  

  const BookmarkActive = __ReactNativeSvgLoader(import('./assets/BookmarkActive.svg'));
  

  const BookmarkActiveBlue = __ReactNativeSvgLoader(import('./assets/BookmarkActiveBlue.svg'));
  

  const BookmarkBlueActive = __ReactNativeSvgLoader(import('./assets/BookmarkBlueActive.svg'));
  

  const BookmarkBlueDark = __ReactNativeSvgLoader(import('./assets/BookmarkBlueDark.svg'));
  

  const BookmarkDark = __ReactNativeSvgLoader(import('./assets/BookmarkDark.svg'));
  

  const BookmarkDisabled = __ReactNativeSvgLoader(import('./assets/BookmarkDisabled.svg'));
  

  const BookmarkResting = __ReactNativeSvgLoader(import('./assets/BookmarkResting.svg'));
  

  const BrightnessMediumActive = __ReactNativeSvgLoader(import('./assets/BrightnessMediumActive.svg'));
  

  const BrightnessMediumDark = __ReactNativeSvgLoader(import('./assets/BrightnessMediumDark.svg'));
  

  const BrightnessMediumDisabled = __ReactNativeSvgLoader(import('./assets/BrightnessMediumDisabled.svg'));
  

  const BrightnessMediumResting = __ReactNativeSvgLoader(import('./assets/BrightnessMediumResting.svg'));
  

  const BrightnessSmallActive = __ReactNativeSvgLoader(import('./assets/BrightnessSmallActive.svg'));
  

  const BrightnessSmallDark = __ReactNativeSvgLoader(import('./assets/BrightnessSmallDark.svg'));
  

  const BrightnessSmallDisabled = __ReactNativeSvgLoader(import('./assets/BrightnessSmallDisabled.svg'));
  

  const BrightnessSmallResting = __ReactNativeSvgLoader(import('./assets/BrightnessSmallResting.svg'));
  

  const BulbActive = __ReactNativeSvgLoader(import('./assets/BulbActive.svg'));
  

  const BulbDark = __ReactNativeSvgLoader(import('./assets/BulbDarkMode.svg'));
  

  const BulbDisabled = __ReactNativeSvgLoader(import('./assets/BulbDisabled.svg'));
  

  const BulbResting = __ReactNativeSvgLoader(import('./assets/BulbResting.svg'));
  

  const CardBasicIInfoResting = __ReactNativeSvgLoader(import('./assets/CardBasicIInfoResting.svg'));
  

  const CardBasicInfoLMActive = __ReactNativeSvgLoader(import('./assets/CardBasicInfoLMActive.svg'));
  

  const CardBasicInfoLMDisabled = __ReactNativeSvgLoader(import('./assets/CardBasicInfoLMDisabled.svg'));
  

  const CardBasicInfoResting = __ReactNativeSvgLoader(import('./assets/CardBasicInfoResting.svg'));
  

  const CardSettingLMActive = __ReactNativeSvgLoader(import('./assets/CardSettingLMActive.svg'));
  

  const CardSettingLMDisabled = __ReactNativeSvgLoader(import('./assets/CardSettingLMDisabled.svg'));
  

  const CardSettingLMResting = __ReactNativeSvgLoader(import('./assets/CardSettingLMResting.svg'));
  

  const CheckBWDarkMode = __ReactNativeSvgLoader(import('./assets/CheckBWDarkMode.svg'));
  

  const CheckBWLightMode = __ReactNativeSvgLoader(import('./assets/CheckBWLightMode.svg'));
  

  const CheckInversionDark = __ReactNativeSvgLoader(import('./assets/CheckInversionDark.svg'));
  

  const CheckInversionLight = __ReactNativeSvgLoader(import('./assets/CheckInversionLight.svg'));
  

  const CheckOriginalDark = __ReactNativeSvgLoader(import('./assets/CheckOriginalDark.svg'));
  

  const CheckOriginalLight = __ReactNativeSvgLoader(import('./assets/CheckOriginalLight.svg'));
  

  const CheckOriginalSuccess = __ReactNativeSvgLoader(import('./assets/CheckOriginalSuccess.svg'));
  

  const CheckSuccessDark = __ReactNativeSvgLoader(import('./assets/CheckSuccessDark.svg'));
  

  const CheckSuccessLight = __ReactNativeSvgLoader(import('./assets/CheckSuccessLight.svg'));
  

  const ChevronDownActive = __ReactNativeSvgLoader(import('./assets/ChevronDownActive.svg'));
  

  const ChevronDownDark = __ReactNativeSvgLoader(import('./assets/ChevronDownDark.svg'));
  

  const ChevronDownDisabled = __ReactNativeSvgLoader(import('./assets/ChevronDownDisabled.svg'));
  

  const ChevronDownResting = __ReactNativeSvgLoader(import('./assets/ChevronDownResting.svg'));
  

  const ChevronLeftActive = __ReactNativeSvgLoader(import('./assets/ChevronLeftActive.svg'));
  

  const ChevronLeftDark = __ReactNativeSvgLoader(import('./assets/ChevronLeftDark.svg'));
  

  const ChevronLeftDisabled = __ReactNativeSvgLoader(import('./assets/ChevronLeftDisabled.svg'));
  

  const ChevronLeftResting = __ReactNativeSvgLoader(import('./assets/ChevronLeftResting.svg'));
  

  const ChevronRightActive = __ReactNativeSvgLoader(import('./assets/ChevronRightActive.svg'));
  

  const ChevronRightDark = __ReactNativeSvgLoader(import('./assets/ChevronRightDark.svg'));
  

  const ChevronRightDisabled = __ReactNativeSvgLoader(import('./assets/ChevronRightDisabled.svg'));
  

  const ChevronRightResting = __ReactNativeSvgLoader(import('./assets/ChevronRightResting.svg'));
  

  const ChevronRightSmallActive = __ReactNativeSvgLoader(import('./assets/ChevronRightSmallActive.svg'));
  

  const ChevronRightSmallDark = __ReactNativeSvgLoader(import('./assets/ChevronRightSmallDark.svg'));
  

  const ChevronRightSmallDisabled = __ReactNativeSvgLoader(import('./assets/ChevronRightSmallDisabled.svg'));
  

  const ChevronRightSmallResting = __ReactNativeSvgLoader(import('./assets/ChevronRightSmallResting.svg'));
  

  const ChevronUpActive = __ReactNativeSvgLoader(import('./assets/ChevronUpActive.svg'));
  

  const ChevronUpDark = __ReactNativeSvgLoader(import('./assets/ChevronUpDark.svg'));
  

  const ChevronUpDisabled = __ReactNativeSvgLoader(import('./assets/ChevronUpDisabled.svg'));
  

  const ChevronUpResting = __ReactNativeSvgLoader(import('./assets/ChevronUpResting.svg'));
  

  const Clock = __ReactNativeSvgLoader(import('./assets/Clock.svg'));
  

  const ClockActive = __ReactNativeSvgLoader(import('./assets/ClockActive.svg'));
  

  const ClockBackground = __ReactNativeSvgLoader(import('./assets/ClockBackground.svg'));
  

  const ClockDark = __ReactNativeSvgLoader(import('./assets/ClockDark.svg'));
  

  const ClockDisabled = __ReactNativeSvgLoader(import('./assets/ClockDisabled.svg'));
  

  const ClockGreen = __ReactNativeSvgLoader(import('./assets/ClockGreen.svg'));
  

  const ClockResting = __ReactNativeSvgLoader(import('./assets/ClockResting.svg'));
  

  const CloseDM = __ReactNativeSvgLoader(import('./assets/CloseDM.svg'));
  

  const CloseLM = __ReactNativeSvgLoader(import('./assets/CloseLM.svg'));
  

  const CommitmentActiveBlue = __ReactNativeSvgLoader(import('./assets/CommitmentActiveBlue.svg'));
  

  const CommitmentBellDarkWC = __ReactNativeSvgLoader(import('./assets/CommitmentBellDarkWC.svg'));
  

  const CommitmentBellDisabled = __ReactNativeSvgLoader(import('./assets/CommitmentBellDisabled.svg'));
  

  const CommitmentsActive = __ReactNativeSvgLoader(import('./assets/CommitmentsActive.svg'));
  

  const CommitmentsDark = __ReactNativeSvgLoader(import('./assets/CommitmentsDark.svg'));
  

  const CommitmentsDisabled = __ReactNativeSvgLoader(import('./assets/CommitmentsDisabled.svg'));
  

  const CommitmentsResting = __ReactNativeSvgLoader(import('./assets/CommitmentsResting.svg'));
  

  const CompanyActive = __ReactNativeSvgLoader(import('./assets/CompanyActive.svg'));
  

  const CompanyDisabled = __ReactNativeSvgLoader(import('./assets/CompanyDisabled.svg'));
  

  const CompanyResting = __ReactNativeSvgLoader(import('./assets/CompanyResting.svg'));
  

  const CulchaFanClubL = __ReactNativeSvgLoader(import('./assets/CulchaFanClubL.svg'));
  

  const CulchaLogoDark = __ReactNativeSvgLoader(import('./assets/CulchaLogoDark.svg'));
  

  const CulchaLogoLight = __ReactNativeSvgLoader(import('./assets/CulchaLogoLight.svg'));
  

  const CulchaLogoText = __ReactNativeSvgLoader(import('./assets/CulchaLogoText.svg'));
  

  const DashboardActive = __ReactNativeSvgLoader(import('./assets/DashboardActive.svg'));
  

  const DashboardDark = __ReactNativeSvgLoader(import('./assets/DashboardDark.svg'));
  

  const DashboardDisabled = __ReactNativeSvgLoader(import('./assets/DashboardDisabled.svg'));
  

  const DashboardResting = __ReactNativeSvgLoader(import('./assets/DashboardResting.svg'));
  

  const DeleteDarkMode = __ReactNativeSvgLoader(import('./assets/DeleteDarkMode.svg'));
  

  const DeleteTrashActive = __ReactNativeSvgLoader(import('./assets/DeleteTrashActive.svg'));
  

  const DeleteTrashResting = __ReactNativeSvgLoader(import('./assets/DeleteTrashResting.svg'));
  

  const DesktopSelected = __ReactNativeSvgLoader(import('./assets/DesktopSelected.svg'));
  

  const DesktopUnselected = __ReactNativeSvgLoader(import('./assets/DesktopUnselected.svg'));
  

  const Digitilize = __ReactNativeSvgLoader(import('./assets/Digitize.svg'));
  

  const DigitilizeBackground = __ReactNativeSvgLoader(import('./assets/DigitizeBackground.svg'));
  

  const DotPaginationActive = __ReactNativeSvgLoader(import('./assets/DotPaginationActive.svg'));
  

  const DotPaginationResting = __ReactNativeSvgLoader(import('./assets/DotPaginationResting.svg'));
  

  const EditActive = __ReactNativeSvgLoader(import('./assets/EditActive.svg'));
  

  const EditResting = __ReactNativeSvgLoader(import('./assets/EditResting.svg'));
  

  const Error = __ReactNativeSvgLoader(import('./assets/Error.svg'));
  

  const ErrorFill = __ReactNativeSvgLoader(import('./assets/ErrorFill.svg'));
  

  const ErrorFillDark = __ReactNativeSvgLoader(import('./assets/ErrorFillDark.svg'));
  

  const ExpandActive = __ReactNativeSvgLoader(import('./assets/ExpandActive.svg'));
  

  const ExpandDark = __ReactNativeSvgLoader(import('./assets/ExpandDark.svg'));
  

  const ExpandDisabled = __ReactNativeSvgLoader(import('./assets/ExpandDisabled.svg'));
  

  const ExpandResting = __ReactNativeSvgLoader(import('./assets/ExpandResting.svg'));
  

  const Eye = __ReactNativeSvgLoader(import('./assets/Eye.svg'));
  

  const EyeBackground = __ReactNativeSvgLoader(import('./assets/EyeBackground.svg'));
  

  const FWDActive = __ReactNativeSvgLoader(import('./assets/FWDActive.svg'));
  

  const FWDDark = __ReactNativeSvgLoader(import('./assets/FWDDark.svg'));
  

  const FWDDisabled = __ReactNativeSvgLoader(import('./assets/FWDDisabled.svg'));
  

  const FWDResting = __ReactNativeSvgLoader(import('./assets/FWDResting.svg'));
  

  const FaceIdActive = __ReactNativeSvgLoader(import('./assets/FaceIdActive.svg'));
  

  const FaceIdDark = __ReactNativeSvgLoader(import('./assets/FaceIdDark.svg'));
  

  const FaceIdDisabled = __ReactNativeSvgLoader(import('./assets/FaceIdDisabled.svg'));
  

  const FaceIdResting = __ReactNativeSvgLoader(import('./assets/FaceIdResting.svg'));
  

  const FastLearnerL = __ReactNativeSvgLoader(import('./assets/FastLearnerL.svg'));
  

  const ForwardActive = __ReactNativeSvgLoader(import('./assets/ForwardActive.svg'));
  

  const ForwardResting = __ReactNativeSvgLoader(import('./assets/ForwardResting.svg'));
  

  const FullscreenActive = __ReactNativeSvgLoader(import('./assets/FullscreenActive.svg'));
  

  const FullscreenDark = __ReactNativeSvgLoader(import('./assets/FullscreenDark.svg'));
  

  const FullscreenDisabled = __ReactNativeSvgLoader(import('./assets/FullscreenDisabled.svg'));
  

  const FullscreenResting = __ReactNativeSvgLoader(import('./assets/FullscreenResting.svg'));
  

  const ImageIconColorActiveLM = __ReactNativeSvgLoader(import('./assets/ImageIconColorActiveLM.svg'));
  

  const ImageIconColorDisabledLM = __ReactNativeSvgLoader(import('./assets/ImageIconColorDisabledLM.svg'));
  

  const ImageNotUploaded = __ReactNativeSvgLoader(import('./assets/ImageNotUploaded.svg'));
  

  const ImageUploaded = __ReactNativeSvgLoader(import('./assets/ImageUploaded.svg'));
  

  const ImageUploading = __ReactNativeSvgLoader(import('./assets/ImageUploading.svg'));
  

  const InfoDark = __ReactNativeSvgLoader(import('./assets/InfoDark.svg'));
  

  const InfoResting = __ReactNativeSvgLoader(import('./assets/InfoResting.svg'));
  

  const InfoRoundActive = __ReactNativeSvgLoader(import('./assets/InfoRoundActive.svg'));
  

  const InfoRoundDarkResting = __ReactNativeSvgLoader(import('./assets/InfoRoundDarkResting.svg'));
  

  const InfoRoundDisabled = __ReactNativeSvgLoader(import('./assets/InfoRoundDisabled.svg'));
  

  const InfoRoundResting = __ReactNativeSvgLoader(import('./assets/InfoRoundResting.svg'));
  

  const ItalicActive = __ReactNativeSvgLoader(import('./assets/ItalicActive.svg'));
  

  const ItalicResting = __ReactNativeSvgLoader(import('./assets/ItalicResting.svg'));
  

  const KeyPadActive = __ReactNativeSvgLoader(import('./assets/KeyPadActive.svg'));
  

  const KeyPadDark = __ReactNativeSvgLoader(import('./assets/KeyPadDark.svg'));
  

  const KeyPadDisabled = __ReactNativeSvgLoader(import('./assets/KeyPadDisabled.svg'));
  

  const KeyPadResting = __ReactNativeSvgLoader(import('./assets/KeyPadResting.svg'));
  

  const LMUploadedIconActive = __ReactNativeSvgLoader(import('./assets/LMUploadedIconActive.svg'));
  

  const LMUploadedIconResting = __ReactNativeSvgLoader(import('./assets/LMUploadedIconResting.svg'));
  

  const LatMenuActive = __ReactNativeSvgLoader(import('./assets/LatMenuActive.svg'));
  

  const LatMenuDark = __ReactNativeSvgLoader(import('./assets/LatMenuDark.svg'));
  

  const LatMenuDisabled = __ReactNativeSvgLoader(import('./assets/LatMenuDisabled.svg'));
  

  const LatMenuResting = __ReactNativeSvgLoader(import('./assets/LatMenuResting.svg'));
  

  const LightModeActiveImage = __ReactNativeSvgLoader(import('./assets/LightModeActiveImage.svg'));
  

  const LightModeRestingImage = __ReactNativeSvgLoader(import('./assets/LightModeRestingImage.svg'));
  

  const LightModelImageDisabled = __ReactNativeSvgLoader(import('./assets/LightModelImageDisabled.svg'));
  

  const LightningActive = __ReactNativeSvgLoader(import('./assets/LightningActive.svg'));
  

  const LightningColor = __ReactNativeSvgLoader(import('./assets/LightningColor.svg'));
  

  const LightningDark = __ReactNativeSvgLoader(import('./assets/LightningDark.svg'));
  

  const LightningDisabled = __ReactNativeSvgLoader(import('./assets/LightningDisabled.svg'));
  

  const LightningResting = __ReactNativeSvgLoader(import('./assets/LightningResting.svg'));
  

  const LightningSmallActive = __ReactNativeSvgLoader(import('./assets/LightningSmallActive.svg'));
  

  const LightningSmallDark = __ReactNativeSvgLoader(import('./assets/LightningSmallDark.svg'));
  

  const LightningSmallDisabled = __ReactNativeSvgLoader(import('./assets/LightningSmallDisabled.svg'));
  

  const LightningSmallResting = __ReactNativeSvgLoader(import('./assets/LightningSmallResting.svg'));
  

  const LightningYellow = __ReactNativeSvgLoader(import('./assets/LightningYellow.svg'));
  

  const LinkActive = __ReactNativeSvgLoader(import('./assets/LinkActive.svg'));
  

  const LinkDark = __ReactNativeSvgLoader(import('./assets/LinkDark.svg'));
  

  const LinkDisabled = __ReactNativeSvgLoader(import('./assets/LinkDisabled.svg'));
  

  const LinkResting = __ReactNativeSvgLoader(import('./assets/LinkResting.svg'));
  

  const ListActive = __ReactNativeSvgLoader(import('./assets/ListActive.svg'));
  

  const ListDark = __ReactNativeSvgLoader(import('./assets/ListDark.svg'));
  

  const ListDisabled = __ReactNativeSvgLoader(import('./assets/ListDisabled.svg'));
  

  const ListResting = __ReactNativeSvgLoader(import('./assets/ListResting.svg'));
  

  const LockClosedColor = __ReactNativeSvgLoader(import('./assets/LockClosedColor.svg'));
  

  const LockDarkModeActive = __ReactNativeSvgLoader(import('./assets/LockDarkModeActive.svg'));
  

  const LockDarkModeDisabled = __ReactNativeSvgLoader(import('./assets/LockDarkModeDisabled.svg'));
  

  const LockDarkModeResting = __ReactNativeSvgLoader(import('./assets/LockDarkModeResting.svg'));
  

  const LockLigthModeActive = __ReactNativeSvgLoader(import('./assets/LockLigthModeActive.svg'));
  

  const LockLigthModeDisabled = __ReactNativeSvgLoader(import('./assets/LockLigthModeDisabled.svg'));
  

  const LockLigthModeResting = __ReactNativeSvgLoader(import('./assets/LockLigthModeResting.svg'));
  

  const MedalActive = __ReactNativeSvgLoader(import('./assets/MedalActive.svg'));
  

  const MedalDark = __ReactNativeSvgLoader(import('./assets/MedalDark.svg'));
  

  const MedalDisabled = __ReactNativeSvgLoader(import('./assets/MedalDisabled.svg'));
  

  const MedalResting = __ReactNativeSvgLoader(import('./assets/MedalResting.svg'));
  

  const MinusActive = __ReactNativeSvgLoader(import('./assets/MinusActive.svg'));
  

  const MinusDark = __ReactNativeSvgLoader(import('./assets/MinusDark.svg'));
  

  const MinusDisabled = __ReactNativeSvgLoader(import('./assets/MinusDisabled.svg'));
  

  const MinusResting = __ReactNativeSvgLoader(import('./assets/MinusResting.svg'));
  

  const MobileSelected = __ReactNativeSvgLoader(import('./assets/MobileSelected.svg'));
  

  const MobileUnselected = __ReactNativeSvgLoader(import('./assets/MobileUnselected.svg'));
  

  const ModernCollaborationL = __ReactNativeSvgLoader(import('./assets/ModernCollaborationL.svg'));
  

  const ModernCollaborationM = __ReactNativeSvgLoader(import('./assets/ModernCollaborationM.svg'));
  

  const ModernCollaborationS = __ReactNativeSvgLoader(import('./assets/ModernCollaborationS.svg'));
  

  const ModulesActive = __ReactNativeSvgLoader(import('./assets/ModulesActive.svg'));
  

  const ModulesDark = __ReactNativeSvgLoader(import('./assets/ModulesDark.svg'));
  

  const ModulesDisabled = __ReactNativeSvgLoader(import('./assets/ModulesDisabled.svg'));
  

  const ModulesResting = __ReactNativeSvgLoader(import('./assets/ModulesResting.svg'));
  

  const MyCommitmentsIcon = __ReactNativeSvgLoader(import('./assets/MyCommitmentsIcon.svg'));
  

  const MyCommitmentsIconDark = __ReactNativeSvgLoader(import('./assets/MyCommitmentsIconDark.svg'));
  

  const NewCardLigthModeActive = __ReactNativeSvgLoader(import('./assets/NewCardLigthModeActive.svg'));
  

  const NewCardLigthModeDisabled = __ReactNativeSvgLoader(import('./assets/NewCardLigthModeDisabled.svg'));
  

  const NewCardLigthModeResting = __ReactNativeSvgLoader(import('./assets/NewCardLigthModeResting.svg'));
  

  const NextActive = __ReactNativeSvgLoader(import('./assets/NextActive.svg'));
  

  const NextDark = __ReactNativeSvgLoader(import('./assets/NextDark.svg'));
  

  const NextDisabled = __ReactNativeSvgLoader(import('./assets/NextDisabled.svg'));
  

  const NextResting = __ReactNativeSvgLoader(import('./assets/NextResting.svg'));
  

  const NotSeenActive = __ReactNativeSvgLoader(import('./assets/NotSeenActive.svg'));
  

  const NotSeenDark = __ReactNativeSvgLoader(import('./assets/NotSeenDark.svg'));
  

  const NotSeenDisabled = __ReactNativeSvgLoader(import('./assets/NotSeenDisabled.svg'));
  

  const NotSeenResting = __ReactNativeSvgLoader(import('./assets/NotSeenResting.svg'));
  

  const NotableLeaderL = __ReactNativeSvgLoader(import('./assets/NotableLeaderL.svg'));
  

  const Okay = __ReactNativeSvgLoader(import('./assets/Okay.svg'));
  

  const OkayDark = __ReactNativeSvgLoader(import('./assets/OkayDark.svg'));
  

  const OkayGreen = __ReactNativeSvgLoader(import('./assets/OkayGreen.svg'));
  

  const OptionActive = __ReactNativeSvgLoader(import('./assets/OptionActive.svg'));
  

  const OptionDarkMode = __ReactNativeSvgLoader(import('./assets/OptionDarkMode.svg'));
  

  const OptionDisabled = __ReactNativeSvgLoader(import('./assets/OptionDisabled.svg'));
  

  const OptionResting = __ReactNativeSvgLoader(import('./assets/OptionResting.svg'));
  

  const OverlineActive = __ReactNativeSvgLoader(import('./assets/OverlineActive.svg'));
  

  const OverlineResting = __ReactNativeSvgLoader(import('./assets/OverlineResting.svg'));
  

  const PauseActive = __ReactNativeSvgLoader(import('./assets/PauseActive.svg'));
  

  const PauseDark = __ReactNativeSvgLoader(import('./assets/PauseDark.svg'));
  

  const PauseDisabled = __ReactNativeSvgLoader(import('./assets/PauseDisabled.svg'));
  

  const PauseResting = __ReactNativeSvgLoader(import('./assets/PauseResting.svg'));
  

  const PenM = __ReactNativeSvgLoader(import('./assets/PenM.svg'));
  

  const PenS = __ReactNativeSvgLoader(import('./assets/PenS.svg'));
  

  const PerformanceActive = __ReactNativeSvgLoader(import('./assets/PerformanceActive.svg'));
  

  const PerformanceDark = __ReactNativeSvgLoader(import('./assets/PerformanceDark.svg'));
  

  const PerformanceDisabled = __ReactNativeSvgLoader(import('./assets/PerformanceDisabled.svg'));
  

  const PerformanceResting = __ReactNativeSvgLoader(import('./assets/PerformanceResting.svg'));
  

  const PictureActive = __ReactNativeSvgLoader(import('./assets/PictureActive.svg'));
  

  const PictureDark = __ReactNativeSvgLoader(import('./assets/PictureDark.svg'));
  

  const PictureDisabled = __ReactNativeSvgLoader(import('./assets/PictureDisabled.svg'));
  

  const PictureResting = __ReactNativeSvgLoader(import('./assets/PictureResting.svg'));
  

  const PlayActive = __ReactNativeSvgLoader(import('./assets/PlayActive.svg'));
  

  const PlayDark = __ReactNativeSvgLoader(import('./assets/PlayDark.svg'));
  

  const PlayDisabled = __ReactNativeSvgLoader(import('./assets/PlayDisabled.svg'));
  

  const PlayResting = __ReactNativeSvgLoader(import('./assets/PlayResting.svg'));
  

  const PlusActive = __ReactNativeSvgLoader(import('./assets/PlusActive.svg'));
  

  const PlusDark = __ReactNativeSvgLoader(import('./assets/PlusDark.svg'));
  

  const PlusDisabled = __ReactNativeSvgLoader(import('./assets/PlusDisabled.svg'));
  

  const PlusResting = __ReactNativeSvgLoader(import('./assets/PlusResting.svg'));
  

  const ProfileActive = __ReactNativeSvgLoader(import('./assets/ProfileActive.svg'));
  

  const ProfileDark = __ReactNativeSvgLoader(import('./assets/ProfileDark.svg'));
  

  const ProfileDisabled = __ReactNativeSvgLoader(import('./assets/ProfileDisabled.svg'));
  

  const ProfileResting = __ReactNativeSvgLoader(import('./assets/ProfileResting.svg'));
  

  const QuizAcerL = __ReactNativeSvgLoader(import('./assets/QuizAcerL.svg'));
  

  const QuizActive = __ReactNativeSvgLoader(import('./assets/QuizActive.svg'));
  

  const QuizDark = __ReactNativeSvgLoader(import('./assets/QuizDark.svg'));
  

  const QuizDisabled = __ReactNativeSvgLoader(import('./assets/QuizDisabled.svg'));
  

  const QuizFlashL = __ReactNativeSvgLoader(import('./assets/QuizFlashL.svg'));
  

  const QuizResting = __ReactNativeSvgLoader(import('./assets/QuizResting.svg'));
  

  const RatingStarL = __ReactNativeSvgLoader(import('./assets/RatingStarL.svg'));
  

  const RedoActive = __ReactNativeSvgLoader(import('./assets/RedoActive.svg'));
  

  const RedoDark = __ReactNativeSvgLoader(import('./assets/RedoDark.svg'));
  

  const RedoDisabled = __ReactNativeSvgLoader(import('./assets/RedoDisabled.svg'));
  

  const RedoResting = __ReactNativeSvgLoader(import('./assets/RedoResting.svg'));
  

  const RefreshArrows = __ReactNativeSvgLoader(import('./assets/RefreshArrows.svg'));
  

  const RefreshArrowsDark = __ReactNativeSvgLoader(import('./assets/RefreshArrowsDark.svg'));
  

  const Reslilience = __ReactNativeSvgLoader(import('./assets/Resilience.svg'));
  

  const ReslilienceBackground = __ReactNativeSvgLoader(import('./assets/ResilienceBackground.svg'));
  

  const RightActive = __ReactNativeSvgLoader(import('./assets/RightActive.svg'));
  

  const RightDarkMode = __ReactNativeSvgLoader(import('./assets/RightDarkMode.svg'));
  

  const RightDisabled = __ReactNativeSvgLoader(import('./assets/RightDisabled.svg'));
  

  const RightResting = __ReactNativeSvgLoader(import('./assets/RightResting.svg'));
  

  const SearchActive = __ReactNativeSvgLoader(import('./assets/SearchActive.svg'));
  

  const SearchDark = __ReactNativeSvgLoader(import('./assets/SearchDark.svg'));
  

  const SearchDisabled = __ReactNativeSvgLoader(import('./assets/SearchDisabled.svg'));
  

  const SearchResting = __ReactNativeSvgLoader(import('./assets/SearchResting.svg'));
  

  const SeenActive = __ReactNativeSvgLoader(import('./assets/SeenActive.svg'));
  

  const SeenDark = __ReactNativeSvgLoader(import('./assets/SeenDark.svg'));
  

  const SeenDisabled = __ReactNativeSvgLoader(import('./assets/SeenDisabled.svg'));
  

  const SeenResting = __ReactNativeSvgLoader(import('./assets/SeenResting.svg'));
  

  const ShareActive = __ReactNativeSvgLoader(import('./assets/ShareActive.svg'));
  

  const ShareDark = __ReactNativeSvgLoader(import('./assets/ShareDarkResting.svg'));
  

  const ShareDisabled = __ReactNativeSvgLoader(import('./assets/ShareDisabled.svg'));
  

  const ShareResting = __ReactNativeSvgLoader(import('./assets/ShareResting.svg'));
  

  const SkillPathActive = __ReactNativeSvgLoader(import('./assets/SkillPathActive.svg'));
  

  const SkillPathDark = __ReactNativeSvgLoader(import('./assets/SkillPathDark.svg'));
  

  const SkillPathDisabled = __ReactNativeSvgLoader(import('./assets/SkillPathDisabled.svg'));
  

  const SkillPathResting = __ReactNativeSvgLoader(import('./assets/SkillPathResting.svg'));
  

  const SkipperL = __ReactNativeSvgLoader(import('./assets/SkipperL.svg'));
  

  const SmileySadActive = __ReactNativeSvgLoader(import('./assets/SmileySadActive.svg'));
  

  const SmileySadInactive = __ReactNativeSvgLoader(import('./assets/SmileySadInactive.svg'));
  

  const SpeakingBulb1 = __ReactNativeSvgLoader(import('./assets/SpeakingBulb1.svg'));
  

  const SpeakingBulb1Background = __ReactNativeSvgLoader(import('./assets/SpeakingBulb1Background.svg'));
  

  const SpeakingBulb2 = __ReactNativeSvgLoader(import('./assets/SpeakingBulb2.svg'));
  

  const SpeakingBulb2Background = __ReactNativeSvgLoader(import('./assets/SpeakingBulb2Background.svg'));
  

  const SpeakingBulb2L = __ReactNativeSvgLoader(import('./assets/SpeakingBulb2L.svg'));
  

  const StarEmptyDark = __ReactNativeSvgLoader(import('./assets/StarEmptyDark.svg'));
  

  const StarEmptyResting = __ReactNativeSvgLoader(import('./assets/StarEmptyResting.svg'));
  

  const StarFullDark = __ReactNativeSvgLoader(import('./assets/StarFullDark.svg'));
  

  const StarFullResting = __ReactNativeSvgLoader(import('./assets/StarFullResting.svg'));
  

  const TeamActive = __ReactNativeSvgLoader(import('./assets/TeamActive.svg'));
  

  const TeamDisabled = __ReactNativeSvgLoader(import('./assets/TeamDisabled.svg'));
  

  const TeamResting = __ReactNativeSvgLoader(import('./assets/TeamsResting.svg'));
  

  const TestFemaleActive = __ReactNativeSvgLoader(import('./assets/TestFemaleActive.svg'));
  

  const TestFemaleDark = __ReactNativeSvgLoader(import('./assets/TestFemaleDark.svg'));
  

  const TestFemaleDisabled = __ReactNativeSvgLoader(import('./assets/TestFemaleDisabled.svg'));
  

  const TestFemaleResting = __ReactNativeSvgLoader(import('./assets/TestFemaleResting.svg'));
  

  const TextActive = __ReactNativeSvgLoader(import('./assets/TextActive.svg'));
  

  const TextDark = __ReactNativeSvgLoader(import('./assets/TextDark.svg'));
  

  const TextDisabled = __ReactNativeSvgLoader(import('./assets/TextDisabled.svg'));
  

  const TextResting = __ReactNativeSvgLoader(import('./assets/TextResting.svg'));
  

  const TimerDark = __ReactNativeSvgLoader(import('./assets/TimerDark.svg'));
  

  const TimerResting = __ReactNativeSvgLoader(import('./assets/TimerResting.svg'));
  

  const TouchIdActive = __ReactNativeSvgLoader(import('./assets/TouchIdActive.svg'));
  

  const TouchIdDark = __ReactNativeSvgLoader(import('./assets/TouchIdDark.svg'));
  

  const TouchIdDisabled = __ReactNativeSvgLoader(import('./assets/TouchIdDisabled.svg'));
  

  const TouchIdResting = __ReactNativeSvgLoader(import('./assets/TouchIdResting.svg'));
  

  const Trepicio1 = __ReactNativeSvgLoader(import('./assets/Trapecio1.svg'));
  

  const Trepicio1Background = __ReactNativeSvgLoader(import('./assets/Trapecio1Background.svg'));
  

  const Trepicio2 = __ReactNativeSvgLoader(import('./assets/Trapecio2.svg'));
  

  const Trepicio2Background = __ReactNativeSvgLoader(import('./assets/Trapecio2Background.svg'));
  

  const TrendDown = __ReactNativeSvgLoader(import('./assets/TrendDown.svg'));
  

  const TrendUp = __ReactNativeSvgLoader(import('./assets/TrendUp.svg'));
  

  const UnCheckedActiveCalendar = __ReactNativeSvgLoader(import('./assets/UnCheckedActiveCalendar.svg'));
  

  const UnCheckedDisabledCalendar = __ReactNativeSvgLoader(import('./assets/UnCheckedDisabledCalendar.svg'));
  

  const UnCheckedRestingCalendar = __ReactNativeSvgLoader(import('./assets/UnCheckedRestingCalendar.svg'));
  

  const UnLockLigthModeResting = __ReactNativeSvgLoader(import('./assets/UnLockLigthModeResting.svg'));
  

  const UnderlineActive = __ReactNativeSvgLoader(import('./assets/UnderlineActive.svg'));
  

  const UnderlineResting = __ReactNativeSvgLoader(import('./assets/UnderlineResting.svg'));
  

  const UnlockDarkModeActive = __ReactNativeSvgLoader(import('./assets/UnlockDarkModeActive.svg'));
  

  const UnlockDarkModeResting = __ReactNativeSvgLoader(import('./assets/UnlockDarkModeResting.svg'));
  

  const UnlockDarkModedisabled = __ReactNativeSvgLoader(import('./assets/UnlockDarkModedisabled.svg'));
  

  const UnlockLigthModeActive = __ReactNativeSvgLoader(import('./assets/UnlockLigthModeActive.svg'));
  

  const UnlockLigthModedisabled = __ReactNativeSvgLoader(import('./assets/UnlockLigthModedisabled.svg'));
  

  const UserProfileActive = __ReactNativeSvgLoader(import('./assets/UserProfileActive.svg'));
  

  const UserProfileDark = __ReactNativeSvgLoader(import('./assets/UserProfileDarkResting.svg'));
  

  const UserProfileDisabled = __ReactNativeSvgLoader(import('./assets/UserProfileDisabled.svg'));
  

  const UserProfileResting = __ReactNativeSvgLoader(import('./assets/UserProfileResting.svg'));
  

  const VideoActive = __ReactNativeSvgLoader(import('./assets/VideoActive.svg'));
  

  const VideoDark = __ReactNativeSvgLoader(import('./assets/VideoDark.svg'));
  

  const VideoDisabled = __ReactNativeSvgLoader(import('./assets/VideoDisabled.svg'));
  

  const VideoIconNotUploaded = __ReactNativeSvgLoader(import('./assets/VideoIconNotUploaded.svg'));
  

  const VideoIconUploaded = __ReactNativeSvgLoader(import('./assets/VideoIconUploaded.svg'));
  

  const VideoIconUploading = __ReactNativeSvgLoader(import('./assets/VideoIconUploading.svg'));
  

  const VideoResting = __ReactNativeSvgLoader(import('./assets/VideoResting.svg'));
  

  const Warn = __ReactNativeSvgLoader(import('./assets/Warn.svg'));
  

  const WarnFill = __ReactNativeSvgLoader(import('./assets/WarnFill.svg'));
  

  const WarnFillDark = __ReactNativeSvgLoader(import('./assets/WarnFillDark.svg'));
  

  const WarningActive = __ReactNativeSvgLoader(import('./assets/WarningActive.svg'));
  

  const WarningBWBlack = __ReactNativeSvgLoader(import('./assets/WarningBWBlack.svg'));
  

  const WarningBWLight = __ReactNativeSvgLoader(import('./assets/WarningBWLight.svg'));
  

  const WarningBlackActive = __ReactNativeSvgLoader(import('./assets/WarningBlackActive.svg'));
  

  const WarningBlueActive = __ReactNativeSvgLoader(import('./assets/WarningBlueActive.svg'));
  

  const WarningDark = __ReactNativeSvgLoader(import('./assets/WarningDark.svg'));
  

  const WarningWhiteActive = __ReactNativeSvgLoader(import('./assets/WarningWhiteActive.svg'));
  

  const WikiActive = __ReactNativeSvgLoader(import('./assets/WikiActive.svg'));
  

  const WikiActiveBlue = __ReactNativeSvgLoader(import('./assets/WikiActiveBlue.svg'));
  

  const WikiDark = __ReactNativeSvgLoader(import('./assets/WikiDark.svg'));
  

  const WikiDisabled = __ReactNativeSvgLoader(import('./assets/WikiDisabled.svg'));
  

  const wikiResting = __ReactNativeSvgLoader(import('./assets/WikiResting.svg'));
  
import { Locks } from './assets/lock/Locks'

/* Step 1: copy those and make sure that the only thing you need to change is 'Foo'
 
  const FooActive = __ReactNativeSvgLoader(import('./assets/FooActive.svg'));
  
 
  const FooDark = __ReactNativeSvgLoader(import('./assets/FooDark.svg'));
  
 
  const FooDisabled = __ReactNativeSvgLoader(import('./assets/FooDisabled.svg'));
  
 
  const FooResting = __ReactNativeSvgLoader(import('./assets/FooResting.svg'));
  
*/

/* Step 2: add the icons name (e.g. Foo) to the list of VALID_ICONS */

/* Step 3: copy those into the ICONS object and again make sure the only thing you need to change is 'Foo'
 Foo: {
   active: FooActive,
   dark: FooDark,
   disabled: FooDisabled,
   resting: FooResting
 },
*/

export type VALID_ICONS =
  | 'ArrowHead'
  | 'List'
  | 'ChevronLeft'
  | 'LightningSmall'
  | 'BrightnessSmall'
  | 'BrightnessMedium'
  | 'Binocular'
  | 'Timer'
  | 'Info'
  | 'Option'
  | 'Error'
  | 'ErrorFill'
  | 'Warn'
  | 'WarnFill'
  | 'WarnBW'
  | 'Warning'
  | 'WarningBlack'
  | 'WarningWhite'
  | 'Okay'
  | 'NotSeen'
  | 'Bulb'
  | 'ArrowRight'
  | 'ArrowLeft'
  | 'ChevronDown'
  | 'ChevronRight'
  | 'ChevronRightSmall'
  | 'ChevronUp'
  | 'Dashboard'
  | 'Fullscreen'
  | 'LatMenu'
  | 'Medal'
  | 'Modules'
  | 'Profile'
  | 'Seen'
  | 'TestFemale'
  | 'Bookmark'
  | 'Back'
  | 'BWD'
  | 'FWD'
  | 'Next'
  | 'Pause'
  | 'Play'
  | 'AUDIO'
  | 'PICTURE'
  | 'QUOTE'
  | 'TEXT'
  | 'VIDEO'
  | 'Plus'
  | 'Minus'
  | 'EXTERNAL_LINK'
  | 'Clock'
  | 'Expand'
  | 'MyCommitmentsIcon'
  | 'RefreshArrows'
  | 'DotPagination'
  | 'Commitments'
  | 'CheckBW'
  | 'Check'
  | 'CheckSuccess'
  | 'CheckInversion'
  | 'Touch'
  | 'KeyPad'
  | 'FaceId'
  | 'Wiki'
  | 'BackSpace'
  | 'Performance'
  | 'SkillPath'
  | 'ModernCollaboration'
  | 'Quiz'
  | 'Share'
  | 'TrendUp'
  | 'TrendDown'
  | 'StarEmpty'
  | 'StarFull'
  | 'Pen'
  | 'SmileySad'
  | 'CommitmentBell'
  | 'Award'
  | 'Lightning'
  | 'LightningYellow'
  | 'CulchaLogo'
  | 'Search'
  | 'SpeakingBulb2'
  | 'Skipper'
  | 'RatingStar'
  | 'QuizAcer'
  | 'FastLearner'
  | 'NotableLeader'
  | 'CulchaFanClub'
  | 'QuizFlash'
  | 'BarsTransparent'
  | 'BarsBackground'
  | 'ClockTransparent'
  | 'ClockBackground'
  | 'DigitilizeTransparent'
  | 'DigitilizeBackground'
  | 'SpeakingBulb1Transparent'
  | 'SpeakingBulb1Background'
  | 'SpeakingBulb2Transparent'
  | 'SpeakingBulb2Background'
  | 'ReslilienceTransparent'
  | 'ReslilienceBackground'
  | 'Trepecio1Trasnparent'
  | 'Trepicio1Background'
  | 'Trepecio2Transparent'
  | 'Trepicio2Background'
  | 'EyeTransparent'
  | 'EyeBackground'
  | 'InfoRound'
  | 'LockClosed'
  | 'LockOpen'
  | 'Redo'
  | 'ImageIconLM'
  | 'LockLM'
  | 'UnLockLM'
  | 'CalendarUnChecked'
  | 'Team'
  | 'Company'
  | 'Edit'

type IconState = Record<string, React.ElementType>
type IconsType = Record<VALID_ICONS, IconState>

export const ICONS: { [iconName in string]: { [state in string]: React.ElementType } } = {
  Company: {
    active: CompanyActive,
    disabled: CompanyDisabled,
    resting: CompanyResting,
  },
  Team: {
    active: TeamActive,
    disabled: TeamDisabled,
    resting: TeamResting,
  },
  CalendarUnChecked: {
    active: UnCheckedActiveCalendar,
    disabled: UnCheckedDisabledCalendar,
    resting: UnCheckedRestingCalendar,
  },
  ArrowHead: {
    active: ArrowHeadActive,
    resting: ArrowHeadResting,
  },
  ImageIconColorLM: {
    active: ImageIconColorActiveLM,
    disabled: ImageIconColorDisabledLM,
  },
  UploadedIconLM: {
    active: LMUploadedIconActive,
    resting: LMUploadedIconResting,
  },
  ImageIconLM: {
    active: LightModeActiveImage,
    disabled: LightModelImageDisabled,
    resting: LightModeRestingImage,
  },
  CloseLM: {
    active: CloseLM,
    resting: CloseDM,
  },
  CardBasicInfoLM: {
    active: CardBasicInfoLMActive,
    disabled: CardBasicInfoLMDisabled,
    resting: CardBasicIInfoResting,
  },
  CardSettingLM: {
    active: CardSettingLMActive,
    disabled: CardSettingLMDisabled,
    resting: CardSettingLMResting,
  },
  UnLockLM: {
    active: UnlockLigthModeActive,
    disabled: UnlockLigthModedisabled,
    resting: UnLockLigthModeResting,
  },

  UnLockDM: {
    active: UnlockDarkModeActive,
    disabled: UnlockDarkModedisabled,
    resting: UnlockDarkModeResting,
  },

  LockLM: {
    active: LockLigthModeActive,
    disabled: LockLigthModeDisabled,
    resting: LockLigthModeResting,
  },
  LockDM: {
    active: LockDarkModeActive,
    disabled: LockDarkModeDisabled,
    resting: LockDarkModeResting,
  },
  NewCard: {
    active: NewCardLigthModeActive,
    disabled: NewCardLigthModeDisabled,
    resting: NewCardLigthModeResting,
  },
  ChevronLeft: {
    active: ChevronLeftActive,
    dark: ChevronLeftDark,
    resting: ChevronLeftResting,
    disabled: ChevronLeftDisabled,
  },
  LightningSmall: {
    active: LightningSmallActive,
    dark: LightningSmallDark,
    disabled: LightningSmallDisabled,
    resting: LightningSmallResting,
  },
  BrightnessSmall: {
    active: BrightnessSmallActive,
    dark: BrightnessSmallDark,
    disabled: BrightnessSmallDisabled,
    resting: BrightnessSmallResting,
  },
  List: {
    active: ListActive,
    dark: ListDark,
    disabled: ListDisabled,
    resting: ListResting,
  },
  BrightnessMedium: {
    active: BrightnessMediumActive,
    dark: BrightnessMediumDark,
    disabled: BrightnessMediumDisabled,
    resting: BrightnessMediumResting,
  },
  Binocular: {
    dark: BinocularDark,
    resting: BinocularResting,
  },
  Timer: {
    dark: TimerDark,
    resting: TimerResting,
  },
  Info: {
    dark: InfoDark,
    resting: InfoResting,
  },
  Option: {
    active: OptionActive,
    dark: OptionDarkMode,
    disabled: OptionDisabled,
    resting: OptionResting,
  },
  Error: {
    active: Error,
    dark: Error,
    disabled: Error,
    resting: Error,
  },
  ErrorFill: {
    active: ErrorFill,
    dark: ErrorFillDark,
    disabled: ErrorFill,
    resting: ErrorFill,
  },
  Warn: {
    active: Warn,
    dark: Warn,
    disabled: Warn,
    resting: Warn,
  },
  WarnFill: {
    active: WarnFill,
    dark: WarnFillDark,
    disabled: WarnFill,
    resting: WarnFill,
  },
  WarnBW: {
    dark: WarningBWLight,
    active: WarningBWBlack,
  },
  Warning: {
    active: WarningActive,
    dark: WarningDark,
  },
  WarningBlack: {
    active: WarningBlackActive,
  },
  WarningWhite: {
    active: WarningWhiteActive,
  },
  WarningBlue: {
    active: WarningBlueActive,
  },
  Okay: {
    active: Okay,
    dark: OkayDark,
    disabled: Okay,
    resting: Okay,
    Green: OkayGreen,
  },
  NotSeen: {
    active: NotSeenActive,
    dark: NotSeenDark,
    disabled: NotSeenDisabled,
    resting: NotSeenResting,
  },
  Bulb: {
    active: BulbActive,
    dark: BulbDark,
    disabled: BulbDisabled,
    resting: BulbResting,
  },
  ArrowRight: {
    active: RightActive,
    dark: RightDarkMode,
    disabled: RightDisabled,
    resting: RightResting,
  },
  ArrowLeft: {
    active: ArrowLeftActive,
    dark: ArrowLeftDark,
    disabled: ArrowLeftDisabled,
    resting: ArrowLeftResting,
  },
  ChevronDown: {
    active: ChevronDownActive,
    dark: ChevronDownDark,
    disabled: ChevronDownDisabled,
    resting: ChevronDownResting,
  },
  ChevronRight: {
    active: ChevronRightActive,
    dark: ChevronRightDark,
    disabled: ChevronRightDisabled,
    resting: ChevronRightResting,
  },
  ChevronRightSmall: {
    active: ChevronRightSmallActive,
    dark: ChevronRightSmallDark,
    disabled: ChevronRightSmallDisabled,
    resting: ChevronRightSmallResting,
  },
  ChevronUp: {
    active: ChevronUpActive,
    dark: ChevronUpDark,
    disabled: ChevronUpDisabled,
    resting: ChevronUpResting,
  },
  Dashboard: {
    active: DashboardActive,
    dark: DashboardDark,
    disabled: DashboardDisabled,
    resting: DashboardResting,
  },
  Fullscreen: {
    active: FullscreenActive,
    dark: FullscreenDark,
    disabled: FullscreenDisabled,
    resting: FullscreenResting,
  },
  LatMenu: {
    active: LatMenuActive,
    dark: LatMenuDark,
    disabled: LatMenuDisabled,
    resting: LatMenuResting,
  },
  Medal: {
    active: MedalActive,
    dark: MedalDark,
    disabled: MedalDisabled,
    resting: MedalResting,
  },
  Modules: {
    active: ModulesActive,
    dark: ModulesDark,
    disabled: ModulesDisabled,
    resting: ModulesResting,
  },
  Profile: {
    active: ProfileActive,
    dark: ProfileDark,
    disabled: ProfileDisabled,
    resting: ProfileResting,
  },
  Seen: {
    active: SeenActive,
    dark: SeenDark,
    disabled: SeenDisabled,
    resting: SeenResting,
  },
  TestFemale: {
    active: TestFemaleActive,
    dark: TestFemaleDark,
    disabled: TestFemaleDisabled,
    resting: TestFemaleResting,
  },
  Bookmark: {
    active: BookmarkActive,
    dark: BookmarkDark,
    disabled: BookmarkDisabled,
    resting: BookmarkResting,
    ActiveBlue: BookmarkActiveBlue,
    UnselectedDark: BookmarkBlueDark,
    Unselected: BookmarkBlueActive,
  },
  Back: {
    active: BackActive,
    dark: BackDark,
    disabled: BackDisabled,
    resting: BackResting,
  },
  BWD: {
    active: BWDActive,
    dark: BWDDark,
    disabled: BWDDisabled,
    resting: BWDResting,
  },
  FWD: {
    active: FWDActive,
    dark: FWDDark,
    disabled: FWDDisabled,
    resting: FWDResting,
  },
  Next: {
    active: NextActive,
    dark: NextDark,
    disabled: NextDisabled,
    resting: NextResting,
  },
  Pause: {
    active: PauseActive,
    dark: PauseDark,
    disabled: PauseDisabled,
    resting: PauseResting,
  },
  Play: {
    active: PlayActive,
    dark: PlayDark,
    disabled: PlayDisabled,
    resting: PlayResting,
  },
  AUDIO: {
    active: AudioActive,
    dark: AudioDark,
    disabled: AudioDisabled,
    resting: AudioResting,
  },
  PICTURE: {
    active: PictureActive,
    dark: PictureDark,
    disabled: PictureDisabled,
    resting: PictureResting,
  },
  QUOTE: {
    active: TextActive,
    dark: TextDark,
    disabled: TextDisabled,
    resting: TextResting,
  },
  TEXT: {
    active: TextActive,
    dark: TextDark,
    disabled: TextDisabled,
    resting: TextResting,
  },
  VIDEO: {
    active: VideoActive,
    dark: VideoDark,
    disabled: VideoDisabled,
    resting: VideoResting,
  },
  Plus: {
    active: PlusActive,
    dark: PlusDark,
    disabled: PlusDisabled,
    resting: PlusResting,
  },
  Minus: {
    active: MinusActive,
    dark: MinusDark,
    disabled: MinusDisabled,
    resting: MinusResting,
  },
  EXTERNAL_LINK: {
    active: LinkActive,
    dark: LinkDark,
    disabled: LinkDisabled,
    resting: LinkResting,
  },
  Clock: {
    active: ClockActive,
    dark: ClockDark,
    disabled: ClockDisabled,
    resting: ClockResting,
    Green: ClockGreen,
  },
  Expand: {
    active: ExpandActive,
    dark: ExpandDark,
    disabled: ExpandDisabled,
    resting: ExpandResting,
  },
  MyCommitmentsIcon: {
    resting: MyCommitmentsIcon,
    active: MyCommitmentsIcon,
    disabled: MyCommitmentsIcon,
    dark: MyCommitmentsIconDark,
  },
  RefreshArrows: {
    resting: RefreshArrows,
    active: RefreshArrows,
    disabled: RefreshArrows,
    dark: RefreshArrowsDark,
  },
  DotPagination: {
    resting: DotPaginationResting,
    active: DotPaginationActive,
  },
  Commitments: {
    active: CommitmentsActive,
    dark: CommitmentsDark,
    disabled: CommitmentsDisabled,
    resting: CommitmentsResting,
    ActiveBlue: CommitmentActiveBlue,
  },
  CheckBW: {
    dark: CheckBWDarkMode,
    active: CheckBWLightMode,
  },
  Check: {
    active: CheckOriginalLight,
    dark: CheckOriginalDark,
    disabled: CheckOriginalLight,
    resting: CheckOriginalSuccess,
  },
  CheckSuccess: {
    active: CheckSuccessLight,
    dark: CheckSuccessDark,
  },
  CheckInversion: {
    active: CheckInversionLight,
    dark: CheckInversionDark,
  },
  Touch: {
    active: TouchIdActive,
    dark: TouchIdDark,
    disabled: TouchIdDisabled,
    resting: TouchIdResting,
  },
  KeyPad: {
    active: KeyPadActive,
    dark: KeyPadDark,
    disabled: KeyPadDisabled,
    resting: KeyPadResting,
  },
  FaceId: {
    active: FaceIdActive,
    dark: FaceIdDark,
    disabled: FaceIdDisabled,
    resting: FaceIdResting,
  },
  Wiki: {
    active: WikiActive,
    dark: WikiDark,
    disabled: WikiDisabled,
    resting: wikiResting,
    ActiveBlue: WikiActiveBlue,
  },
  BackSpace: {
    active: BackSpaceActive,
    dark: BackSpaceActiveDark,
    disabled: BackSpaceDisabled,
    resting: BackSpaceResting,
  },
  Performance: {
    active: PerformanceActive,
    dark: PerformanceDark,
    disabled: PerformanceDisabled,
    resting: PerformanceResting,
  },
  SkillPath: {
    active: SkillPathActive,
    dark: SkillPathDark,
    disabled: SkillPathDisabled,
    resting: SkillPathResting,
  },
  ModernCollaboration: {
    small: ModernCollaborationS,
    medium: ModernCollaborationM,
    large: ModernCollaborationL,
  },
  Quiz: {
    active: QuizActive,
    dark: QuizDark,
    disabled: QuizDisabled,
    resting: QuizResting,
  },
  Share: {
    active: ShareActive,
    dark: ShareDark,
    disabled: ShareDisabled,
    resting: ShareResting,
  },
  TrendUp: {
    active: TrendUp,
    dark: TrendUp,
    disabled: TrendUp,
    resting: TrendUp,
  },
  TrendDown: {
    active: TrendDown,
    dark: TrendDown,
    disabled: TrendDown,
    resting: TrendDown,
  },
  StarEmpty: {
    active: StarEmptyResting,
    dark: StarEmptyDark,
    disabled: StarEmptyResting,
    resting: StarEmptyResting,
  },
  StarFull: {
    active: StarFullResting,
    dark: StarFullDark,
    disabled: StarFullResting,
    resting: StarFullResting,
  },
  Pen: {
    small: PenS,
    medium: PenM,
  },
  SmileySad: {
    active: SmileySadActive,
    disabled: SmileySadInactive,
  },
  CommitmentBell: {
    dark: CommitmentBellDarkWC,
    disabled: CommitmentBellDisabled,
  },
  Award: {
    active: AwardActive,
    dark: AwardDark,
    disabled: AwardDisabled,
    resting: AwardResting,
  },
  Lightning: {
    active: LightningActive,
    disabled: LightningDisabled,
    resting: LightningResting,
    dark: LightningDark,
    medium: LightningColor,
  },
  LightningYellow: {
    active: LightningYellow,
  },
  CulchaLogo: {
    dark: CulchaLogoDark,
    resting: CulchaLogoLight,
    Label: CulchaLogoText,
  },
  Search: {
    active: SearchActive,
    dark: SearchDark,
    disabled: SearchDisabled,
    resting: SearchResting,
  },
  SpeakingBulb2: {
    large: SpeakingBulb2L,
  },
  Skipper: {
    large: SkipperL,
  },
  RatingStar: {
    large: RatingStarL,
  },
  QuizAcer: {
    large: QuizAcerL,
  },
  FastLearner: {
    large: FastLearnerL,
  },
  NotableLeader: {
    large: NotableLeaderL,
  },
  CulchaFanClub: {
    large: CulchaFanClubL,
  },
  QuizFlash: {
    large: QuizFlashL,
  },
  BarsTransparent: {
    active: Bars,
  },
  BarsBackground: {
    active: BarsBackground,
  },
  ClockTransparent: {
    active: Clock,
  },
  ClockBackground: {
    active: ClockBackground,
  },
  DigitilizeTransparent: {
    active: Digitilize,
  },
  DigitilizeBackground: {
    active: DigitilizeBackground,
  },
  SpeakingBulb1Transparent: {
    active: SpeakingBulb1,
  },
  SpeakingBulb1Background: {
    active: SpeakingBulb1Background,
  },
  SpeakingBulb2Transparent: {
    active: SpeakingBulb2,
  },
  SpeakingBulb2Background: {
    active: SpeakingBulb2Background,
  },
  ReslilienceTransparent: {
    active: Reslilience,
  },
  ReslilienceBackground: {
    active: ReslilienceBackground,
  },
  Trepecio1Trasnparent: {
    active: Trepicio1,
  },
  Trepicio1Background: {
    active: Trepicio1Background,
  },
  Trepecio2Transparent: {
    active: Trepicio2,
  },
  Trepicio2Background: {
    active: Trepicio2Background,
  },
  EyeTransparent: {
    active: Eye,
  },
  EyeBackground: {
    active: EyeBackground,
  },
  UserProfile: {
    active: UserProfileActive,
    dark: UserProfileDark,
    disabled: UserProfileDisabled,
    resting: UserProfileResting,
  },
  Desktop: {
    Selected: DesktopSelected,
    Unselected: DesktopUnselected,
  },
  Mobile: {
    Selected: MobileSelected,
    Unselected: MobileUnselected,
  },
  CardBasicInfo: {
    resting: CardBasicInfoResting,
  },
  ArrowDown: {
    active: ArrowDownActive,
    resting: ArrowDownResting,
    disabled: ArrowDownDisabled,
  },
  ArrowUp: {
    active: ArrowUpActive,
    resting: ArrowUpResting,
    disabled: ArrowUpDisabled,
  },
  DeleteTrash: {
    active: DeleteTrashActive,
    resting: DeleteTrashResting,
    dark: DeleteDarkMode,
  },
  Edit: {
    active: EditActive,
    resting: EditResting,
  },

  Bold: {
    active: BoldActive,
    resting: BoldResting,
  },
  Italic: {
    active: ItalicActive,
    resting: ItalicResting,
  },
  Underline: {
    active: UnderlineActive,
    resting: UnderlineResting,
  },
  Overline: {
    active: OverlineActive,
    resting: OverlineResting,
  },
  Backward: {
    active: BackwardActive,
    resting: BackwardResting,
  },
  Forward: {
    active: ForwardActive,
    resting: ForwardResting,
  },
  Image: {
    Uploaded: ImageUploaded,
    Uploading: ImageUploading,
    NotUploaded: ImageNotUploaded,
  },
  Video: {
    Uploaded: VideoIconUploaded,
    Uploading: VideoIconUploading,
    NotUploaded: VideoIconNotUploaded,
  },
  Audio: {
    Uploaded: AudioUploaded,
    Uploading: AudioUploading,
    NotUploaded: AudioNotUploaded,
  },
  InfoRound: {
    active: InfoRoundActive,
    dark: InfoRoundDarkResting,
    disabled: InfoRoundDisabled,
    resting: InfoRoundResting,
  },
  LockClosed: {
    active: Locks.ClosedLigthActive,
    dark: Locks.ClosedDarkResting,
    disabled: Locks.ClosedLigthDisabled,
    resting: Locks.ClosedLigthResting,
    Color: LockClosedColor,
  },
  LockOpen: {
    active: Locks.OpenLightActive,
    dark: Locks.OpenDarkResting,
    disabled: Locks.OpenLightDisabled,
    resting: Locks.OpenLightResting,
  },
  Redo: {
    active: RedoActive,
    dark: RedoDark,
    disabled: RedoDisabled,
    resting: RedoResting,
  },
}

export type IconType = keyof IconsType
export type IconComponentProps = {
  icon: IconType
  active?: boolean
  disabled?: boolean
  dark?: boolean
  height?: number
  theme?: ReactNativePaper.Theme
  size?: 'small' | 'medium' | 'large'
}

const Icon = ({ icon, active, disabled, dark, height, theme, size }: IconComponentProps): JSX.Element => {
  const iconTheme = theme || useTheme()
  const fromIconCache = ICONS[icon]

  if (!fromIconCache || !icon) {
    // eslint-disable-next-line
    console.log(`This icon is missing. Please add this svg to Icon.tsx`)
    // return <SmileySadActive height={height} />
  }

  let addition

  if (disabled) {
    addition = 'disabled'
  } else if (active) {
    addition = 'active'
  } else if ((dark !== null && dark === true) || iconTheme.dark) {
    addition = 'dark'
  } else {
    addition = 'resting'
  }

  if (size) {
    addition = size
  }

  const Comp = fromIconCache[addition]

  return <Comp height={height} />
}

Icon.defaultProps = {
  active: null,
  disabled: false,
  dark: false,
  height: 32,
  theme: undefined,
  size: null,
}

export default Icon

        