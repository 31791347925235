import Logger from '@ts/util/logger/Logger'
import StateChange from './StateChange'
import { Action } from './Action'

const log = new Logger('Dispatcher')
export default class Dispatcher {
  static dispatchStuff: ((p: StateChange) => void) | null = null

  static initCache: StateChange[] = []

  static dispatchStateChange(p: StateChange): void {
    if (Dispatcher.dispatchStuff) {
      // eslint-disable-next-line no-console
      Dispatcher.dispatchStuff(p)
    } else {
      // eslint-disable-next-line no-console
      log.debug('Dispatcher is not ready, caching and delivering later.')
      Dispatcher.initCache.push(p)
    }
  }

  static dispatch(action: Action, payload: unknown): void {
    Dispatcher.dispatchStateChange(new StateChange(action, payload))
  }

  static setDispatch(fn: (p: StateChange) => void): void {
    Dispatcher.dispatchStuff = fn
    setTimeout(Dispatcher.dumpCache, 500)
  }

  static dumpCache(): void {
    while (Dispatcher.initCache.length > 0) {
      Dispatcher.dispatchStateChange(Dispatcher.initCache.pop())
    }
  }
}
